import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import { motion } from 'framer-motion';
import { 
  CalendarIcon, 
  CurrencyDollarIcon, 
  UserGroupIcon, 
  PhotoIcon,
  ClockIcon,
  DocumentTextIcon,
  PencilSquareIcon,
  SparklesIcon
} from '@heroicons/react/24/outline';

import NavBar from '../compoenents/NavBar';
import Footer from '../compoenents/footer';

export default function AddEvent() {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    price: '',
    details: '',
    startTime: '',
    endTime: '',
    registrationDeadline: '',
    maxParticipants: '',
    images: [],
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dragActive, setDragActive] = useState(false);
  const [previewImages, setPreviewImages] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem('authToken'); 
    if (token) {
      const decodedToken = jwtDecode(token);
      if (decodedToken.role !== 'admin') {
        navigate('/');
      } 
    } else {
      navigate('/');
    }
  }, [navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setFormData(prev => ({
      ...prev,
      images: files,
    }));
    setPreviewImages(files.map(file => URL.createObjectURL(file)));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const data = new FormData();
    
    for (let key in formData) {
      if (key === 'images') {
        for (let image of formData.images) {
          data.append('images', image);
        }
      } else {
        data.append(key, formData[key]);
      }
    }

    try {
      await axios.post('https://karem-tours.onrender.com/api/events', data);
      alert('Événement ajouté avec succès!');
      setFormData({
        title: '',
        description: '',
        price: '',
        details: '',
        startTime: '',
        endTime: '',
        registrationDeadline: '',
        maxParticipants: '',
        images: [],
      });
      setPreviewImages([]);
    } catch (error) {
      console.error('Erreur lors de l\'ajout de l\'événement:', error);
      alert('Échec de l\'ajout de l\'événement.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const formFields = [
    { name: 'title', label: 'Titre de l\'événement', type: 'text', icon: PencilSquareIcon },
    { name: 'description', label: 'Description de l\'événement', type: 'textarea', icon: DocumentTextIcon },
    { name: 'price', label: 'Prix du Reservation', type: 'number', icon: CurrencyDollarIcon },
    { name: 'details', label: 'Détails de l\'événement', type: 'textarea', icon: DocumentTextIcon },
    { name: 'startTime', label: 'Heure de début', type: 'datetime-local', icon: ClockIcon },
    { name: 'endTime', label: 'Heure de fin', type: 'datetime-local', icon: ClockIcon },
    { name: 'registrationDeadline', label: 'Date limite d\'inscription', type: 'datetime-local', icon: CalendarIcon },
    { name: 'maxParticipants', label: 'Nombre maximal de participants', type: 'number', icon: UserGroupIcon },
  ];

  return (
    <>
      <NavBar />
      <div className="min-h-screen bg-gradient-to-br from-red-50 to-white py-12 px-4 sm:px-6 lg:px-8 mt-16">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="max-w-5xl mx-auto"
        >
          <div className="text-center mb-8">
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ type: "spring", stiffness: 260, damping: 20 }}
              className="inline-block"
            >
              <SparklesIcon className="h-16 w-16 text-red-600 mx-auto" />
            </motion.div>
            <h1 className="mt-4 text-4xl font-extrabold text-gray-900 tracking-tight">
              Créez Votre Événement Incroyable
            </h1>
            <p className="mt-2 text-lg text-gray-600">
              Remplissez les détails ci-dessous pour donner vie à votre événement
            </p>
          </div>

          <motion.form
            onSubmit={handleSubmit}
            className="bg-white rounded-3xl shadow-2xl overflow-hidden"
          >
            <div className="bg-red-600 py-6 px-8">
              <h2 className="text-xl font-bold text-white flex items-center">
                <CalendarIcon className="h-6 w-6 mr-2" />
                Informations sur l'événement
              </h2>
            </div>

            <div className="p-8">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                {formFields.map((field) => (
                  <motion.div
                    key={field.name}
                    whileHover={{ scale: 1.02 }}
                    className="relative"
                  >
                    <label className="flex items-center text-sm font-medium text-gray-700 mb-2">
                      <field.icon className="h-5 w-5 mr-2 text-red-500" />
                      {field.label}
                    </label>
                    {field.type === 'textarea' ? (
                      <textarea
                        name={field.name}
                        value={formData[field.name]}
                        onChange={handleChange}
                        className="w-full px-4 py-2 border-2 border-gray-200 rounded-xl focus:ring-2 focus:ring-red-500 focus:border-transparent transition duration-200 ease-in-out"
                        required
                      />
                    ) : (
                      <input
                        type={field.type}
                        name={field.name}
                        value={formData[field.name]}
                        onChange={handleChange}
                        className="w-full px-4 py-2 border-2 border-gray-200 rounded-xl focus:ring-2 focus:ring-red-500 focus:border-transparent transition duration-200 ease-in-out"
                        required
                      />
                    )}
                  </motion.div>
                ))}
              </div>

              <motion.div
                whileHover={{ scale: 1.02 }}
                className="mt-8"
                onDragEnter={() => setDragActive(true)}
                onDragLeave={() => setDragActive(false)}
                onDrop={() => setDragActive(false)}
              >
                <label className="flex items-center text-sm font-medium text-gray-700 mb-2">
                  <PhotoIcon className="h-5 w-5 mr-2 text-red-500" />
                  Images de l'événement
                </label>
                <div className={`mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-dashed rounded-xl transition duration-200 ease-in-out ${
                  dragActive ? 'border-red-500 bg-red-50' : 'border-gray-300'
                }`}>
                  <div className="space-y-1 text-center">
                    <PhotoIcon className="mx-auto h-12 w-12 text-gray-400" />
                    <div className="flex text-sm text-gray-600">
                      <label className="relative cursor-pointer bg-white rounded-md font-medium text-red-600 hover:text-red-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-red-500">
                        <span>Télécharger des fichiers</span>
                        <input
                          type="file"
                          name="images"
                          onChange={handleFileChange}
                          className="sr-only"
                          multiple
                          accept="image/*"
                        />
                      </label>
                      <p className="pl-1">ou glisser-déposer</p>
                    </div>
                    <p className="text-xs text-gray-500">PNG, JPG, GIF jusqu'à 10MB</p>
                  </div>
                </div>
                <div className="mt-4 grid grid-cols-2 md:grid-cols-4 gap-4">
                  {previewImages.map((src, index) => (
                    <div key={index} className="flex justify-center">
                      <img src={src} alt={`Aperçu ${index}`} className="h-20 w-20 object-cover rounded-md" />
                    </div>
                  ))}
                </div>
              </motion.div>

              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                type="submit"
                disabled={isSubmitting}
                className={`mt-8 w-full flex justify-center items-center py-3 px-4 rounded-xl text-white text-lg font-semibold bg-gradient-to-r from-red-600 to-red-500 hover:from-red-700 hover:to-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition duration-200 ease-in-out ${
                  isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              >
                {isSubmitting ? (
                  <>
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Création de l'événement...
                  </>
                ) : (
                  <>
                    <SparklesIcon className="h-6 w-6 mr-2" />
                    Lancer l'événement
                  </>
                )}
              </motion.button>
            </div>
          </motion.form>
        </motion.div>
      </div>
      <Footer />
    </>
  );
}