import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import LandingPage from './user pages/LandingPage';
import Home from './user pages/Home';
import EventDetails from './user pages/EventDetails';
import About from './user pages/About';
import AdminPage from './admin pages/Adminpage';
import AddEvent from './admin pages/AddEvent';
import EditEvent from './admin pages/EditEvent';
import EnrollmentConfirmation from './admin pages/enrollmentConfirmation';
import Login from './user pages/login';
import Register from './user pages/register';
import Profile from './user pages/profile';
import SetAdmin from './admin pages/SetAdmin';
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/catalogue" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/eventdetails/:id" element={<EventDetails />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/admin/add-event" element={<AddEvent />} />
        <Route path="/admin/edit/:id" element={<EditEvent />} />
        <Route path="/admin/enrollment/:id" element={<EnrollmentConfirmation />} />
        <Route path="/admin/set-admin" element={<SetAdmin />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/profile" element={<Profile />} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;
