import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import NavBar from '../compoenents/NavBar';
import { motion } from 'framer-motion';
import Footer from '../compoenents/footer';

export default function Profile() {
  const [user, setUser] = useState(null);
  const [enrollments, setEnrollments] = useState({ confirmed: [], unconfirmed: [] });
  const [userId, setUserId] = useState(null);
  const [activeTab, setActiveTab] = useState('confirmed');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const token = sessionStorage.getItem('authToken');
    if (token) {
      const decoded = jwtDecode(token);
      setUserId(decoded.id);
    }
  }, []);

  useEffect(() => {
    if (userId) {
      const fetchData = async () => {
        setIsLoading(true);
        try {
          const [userResponse, enrollmentsResponse] = await Promise.all([
            axios.get(`https://karem-tours.onrender.com/api/users/${userId}`),
            axios.get(`https://karem-tours.onrender.com/api/enrollments/user/${userId}`)
          ]);

          setUser(userResponse.data);
          const confirmed = enrollmentsResponse.data.filter(enrollment => enrollment.enrollmentStatus === true);
          const unconfirmed = enrollmentsResponse.data.filter(enrollment => enrollment.enrollmentStatus === false);
          setEnrollments({ confirmed, unconfirmed });
        } catch (error) {
          console.error('Erreur lors de la récupération des données:', error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchData();
    }
  }, [userId]);

  if (isLoading) return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-red-50 to-gray-50">
      <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-red-600"></div>
    </div>
  );

  if (!user) return null;

  return (
    <div className="min-h-screen bg-gradient-to-br from-red-50 to-gray-50">
      <NavBar />
      <div className="max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 mt-16">
        {/* En-tête du profil */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-gradient-to-r from-red-600 to-red-800 rounded-2xl shadow-xl overflow-hidden mb-8"
        >
          <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
            <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-8">
              <div className="h-24 w-24 rounded-full bg-white/10 backdrop-blur-sm flex items-center justify-center border-2 border-white/20">
                <span className="text-4xl font-bold text-white">
                  {user.fullName?.charAt(0)?.toUpperCase() || 'U'}
                </span>
              </div>
              <div className="text-center md:text-left">
                <h1 className="text-3xl font-bold text-white">{user.fullName}</h1>
                <div className="mt-2 flex flex-col sm:flex-row sm:space-x-6 text-red-100">
                  <span className="flex items-center justify-center sm:justify-start">
                    <svg className="h-5 w-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                    </svg>
                    {user.email}
                  </span>
                  <span className="flex items-center justify-center sm:justify-start">
                    <svg className="h-5 w-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                    </svg>
                    {user.phoneNumber}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </motion.div>

        {/* Cartes de statistiques */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          <motion.div 
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            className="bg-white rounded-xl shadow-lg p-6"
          >
            <div className="text-red-600 text-lg font-semibold mb-2">Total des Voyages</div>
            <div className="text-3xl font-bold">
              {enrollments.confirmed.length + enrollments.unconfirmed.length}
            </div>
          </motion.div>
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="bg-white rounded-xl shadow-lg p-6"
          >
            <div className="text-green-600 text-lg font-semibold mb-2">Confirmés</div>
            <div className="text-3xl font-bold">{enrollments.confirmed.length}</div>
          </motion.div>
          <motion.div 
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            className="bg-white rounded-xl shadow-lg p-6"
          >
            <div className="text-yellow-600 text-lg font-semibold mb-2">En Attente</div>
            <div className="text-3xl font-bold">{enrollments.unconfirmed.length}</div>
          </motion.div>
        </div>

        {/* Section des Voyages */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white rounded-2xl shadow-xl overflow-hidden"
        >
          <div className="border-b border-gray-200">
            <div className="flex space-x-8 px-8">
              <button
                className={`py-4 px-2 text-sm font-medium border-b-2 transition-colors duration-200 ${
                  activeTab === 'confirmed'
                    ? 'border-red-600 text-red-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                }`}
                onClick={() => setActiveTab('confirmed')}
              >
                Voyages Confirmés
              </button>
              <button
                className={`py-4 px-2 text-sm font-medium border-b-2 transition-colors duration-200 ${
                  activeTab === 'unconfirmed'
                    ? 'border-red-600 text-red-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                }`}
                onClick={() => setActiveTab('unconfirmed')}
              >
                Voyages en Attente
              </button>
            </div>
          </div>

          <div className="p-8">
            <div className="grid gap-6">
              {activeTab === 'confirmed' ? (
                enrollments.confirmed.length > 0 ? (
                  enrollments.confirmed.map((enrollment, index) => (
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: index * 0.1 }}
                      key={enrollment._id}
                      className="bg-white border border-gray-200 rounded-xl shadow-sm hover:shadow-md transition-shadow duration-200"
                    >
                      <div className="p-6">
                        <div className="flex flex-col md:flex-row md:items-center md:justify-between">
                          <div className="flex-1">
                            <h3 className="text-lg font-semibold text-gray-900">
                              {enrollment.firstName} {enrollment.lastName}
                            </h3>
                            <div className="mt-2 grid grid-cols-1 md:grid-cols-2 gap-4">
                              <div>
                                <p className="text-sm text-gray-500">Email</p>
                                <p className="font-medium">{enrollment.email}</p>
                              </div>
                              <div>
                                <p className="text-sm text-gray-500">Téléphone</p>
                                <p className="font-medium">{enrollment.phone}</p>
                              </div>
                            </div>
                          </div>
                          <div className="mt-4 md:mt-0">
                            <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-green-100 text-green-800">
                              Confirmé
                            </span>
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  ))
                ) : (
                  <div className="text-center py-12">
                    <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4" />
                    </svg>
                    <h3 className="mt-2 text-sm font-medium text-gray-900">Aucun voyage confirmé</h3>
                    <p className="mt-1 text-sm text-gray-500">Commencez par réserver votre premier voyage.</p>
                  </div>
                )
              ) : (
                enrollments.unconfirmed.length > 0 ? (
                  enrollments.unconfirmed.map((enrollment, index) => (
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: index * 0.1 }}
                      key={enrollment._id}
                      className="bg-white border border-gray-200 rounded-xl shadow-sm hover:shadow-md transition-shadow duration-200"
                    >
                      <div className="p-6">
                        <div className="flex flex-col md:flex-row md:items-center md:justify-between">
                          <div className="flex-1">
                            <h3 className="text-lg font-semibold text-gray-900">
                              {enrollment.firstName} {enrollment.lastName}
                            </h3>
                            <div className="mt-2 grid grid-cols-1 md:grid-cols-2 gap-4">
                              <div>
                                <p className="text-sm text-gray-500">Email</p>
                                <p className="font-medium">{enrollment.email}</p>
                              </div>
                              <div>
                                <p className="text-sm text-gray-500">Téléphone</p>
                                <p className="font-medium">{enrollment.phone}</p>
                              </div>
                            </div>
                          </div>
                          <div className="mt-4 md:mt-0">
                            <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-yellow-100 text-yellow-800">
                              En Attente
                            </span>
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  ))
                ) : (
                  <div className="text-center py-12">
                    <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4" />
                    </svg>
                    <h3 className="mt-2 text-sm font-medium text-gray-900">Aucun voyage en attente</h3>
                    <p className="mt-1 text-sm text-gray-500">Tous vos voyages sont confirmés.</p>
                  </div>
                )
              )}
            </div>
          </div>
        </motion.div>
      </div>
      <Footer />
    </div>
  );
}