import React, { useState } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import { UserIcon, EnvelopeIcon, PhoneIcon, LockClosedIcon } from '@heroicons/react/24/outline';
import NavBar from '../compoenents/NavBar';

export default function Register() {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
    password: '',
    confirmPassword: '',
  });

  const [passwordError, setPasswordError] = useState('');
  const [apiError, setApiError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [validationErrors, setValidationErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const errors = {};
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email regex

    if (formData.fullName.length < 4) {
      errors.fullName = 'Le nom complet doit contenir au moins 4 caractères';
    }
    if (!emailPattern.test(formData.email)) {
      errors.email = 'Veuillez entrer une adresse e-mail valide';
    }
    if (!/^\d{8}$/.test(formData.phoneNumber)) {
      errors.phoneNumber = 'Le numéro de téléphone doit contenir exactement 8 chiffres';
    }
    if (
      formData.password.length < 6 || 
      !/[A-Z]/.test(formData.password) && 
      !/\d/.test(formData.password) && 
      !/[!@#$%^&*]/.test(formData.password)
    ) {
      errors.password = 'Le mot de passe doit contenir au moins 6 caractères et inclure au moins une majuscule, un chiffre ou un symbole spécial';
    }
    if (formData.password !== formData.confirmPassword) {
      errors.confirmPassword = 'Les mots de passe ne correspondent pas';
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0; // returns true if no errors
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    setPasswordError('');
    setApiError('');
    setSuccessMessage('');
  
    if (!validateForm()) {
      return; // Prevent submission if validation fails
    }
  
    const payload = {
      fullName: formData.fullName,
      email: formData.email,
      phoneNumber: formData.phoneNumber,
      password: formData.password,
    };
  
    try {
      const response = await axios.post('https://karem-tours.onrender.com/api/users', payload);
      setSuccessMessage('Inscription réussie!');
      window.location.href = '/login';
    } catch (error) {
      setApiError(error.response?.data?.error || 'Une erreur s\'est produite lors de l\'inscription.');
    }
  };

  return (
    <>
      <NavBar />  
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-red-50 to-white py-12 px-4 sm:px-6 lg:px-8 mt-16">
        <div className="max-w-md w-full space-y-8">
          <div className="text-center">
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ type: "spring", stiffness: 260, damping: 20 }}
              className="inline-block"
            >
              <UserIcon className="h-16 w-16 text-red-600 mx-auto" />
            </motion.div>
            <h2 className="mt-4 text-3xl font-extrabold text-gray-900">Créez votre compte</h2>
          </div>

          <motion.form
            onSubmit={handleSubmit}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="mt-8 space-y-6 bg-white p-8 rounded-3xl shadow-lg"
          >
            <div className="space-y-4">
              <div className="relative">
                <label htmlFor="fullName" className="text-sm font-medium text-gray-700">
                  Nom Complet
                </label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <span className="inline-flex items-center px-3 rounded-l-md bg-gray-100 border border-r-0 border-gray-300 text-gray-500 text-sm">
                    <UserIcon className="h-5 w-5" />
                  </span>
                  <input
                    id="fullName"
                    name="fullName"
                    type="text"
                    required
                    value={formData.fullName}
                    onChange={handleChange}
                    placeholder='Jean Dupont'
                    className="appearance-none rounded-r-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
                  />
                </div>
                {validationErrors.fullName && <p className="text-red-500 text-sm mt-2">{validationErrors.fullName}</p>}
              </div>

              <div className="relative">
                <label htmlFor="email" className="text-sm font-medium text-gray-700">
                  E-mail
                </label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <span className="inline-flex items-center px-3 rounded-l-md bg-gray-100 border border-r-0 border-gray-300 text-gray-500 text-sm">
                    <EnvelopeIcon className="h-5 w-5" />
                  </span>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    required
                    value={formData.email}
                    onChange={handleChange}
                    placeholder='exemple@exemple.com'
                    className="appearance-none rounded-r-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
                  />
                </div>
                {validationErrors.email && <p className="text-red-500 text-sm mt-2">{validationErrors.email}</p>}
              </div>

              <div className="relative">
                <label htmlFor="phoneNumber" className="text-sm font-medium text-gray-700">
                  Numéro de Téléphone
                </label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <span className="inline-flex items-center px-3 rounded-l-md bg-gray-100 border border-r-0 border-gray-300 text-gray-500 text-sm">
                    <PhoneIcon className="h-5 w-5" />
                  </span>
                  <input
                    id="phoneNumber"
                    name="phoneNumber"
                    type="tel"
                    required
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    placeholder='+216 55 555 555'
                    className="appearance-none rounded-r-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
                  />
                </div>
                {validationErrors.phoneNumber && <p className="text-red-500 text-sm mt-2">{validationErrors.phoneNumber}</p>}
              </div>

              <div className="relative">
                <label htmlFor="password" className="text-sm font-medium text-gray-700">
                  Mot de Passe
                </label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <span className="inline-flex items-center px-3 rounded-l-md bg-gray-100 border border-r-0 border-gray-300 text-gray-500 text-sm">
                    <LockClosedIcon className="h-5 w-5" />
                  </span>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    required
                    value={formData.password}
                    onChange={handleChange}
                    placeholder='********'
                    className="appearance-none rounded-r-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
                  />
                </div>
                {validationErrors.password && <p className="text-red-500 text-sm mt-2">{validationErrors.password}</p>}
              </div>

              <div className="relative">
                <label htmlFor="confirmPassword" className="text-sm font-medium text-gray-700">
                  Confirmez le Mot de Passe
                </label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <span className="inline-flex items-center px-3 rounded-l-md bg-gray-100 border border-r-0 border-gray-300 text-gray-500 text-sm">
                    <LockClosedIcon className="h-5 w-5" />
                  </span>
                  <input
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    required
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    placeholder='********'
                    className="appearance-none rounded-r-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
                  />
                </div>
                {validationErrors.confirmPassword && <p className="text-red-500 text-sm mt-2">{validationErrors.confirmPassword}</p>}
              </div>
            </div>

            {apiError && <p className="text-red-500 text-sm">{apiError}</p>}
            {successMessage && <p className="text-green-500 text-sm">{successMessage}</p>}

            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Inscription
            </button>
          </motion.form>
        </div>
      </div>
    </>
  );
}