import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import {jwtDecode} from 'jwt-decode';

import { 
  FaCheck, 
  FaTimes, 
  FaClock, 
  FaUserCheck, 
  FaUserClock,
  FaEnvelope,
  FaPhone,
  FaCalendarAlt,
  FaSearch,
  FaMapMarkerAlt,
  FaBirthdayCake,
  FaChild
} from 'react-icons/fa';
import NavBar from '../compoenents/NavBar';
import Footer from '../compoenents/footer';

export default function EnrollmentConfirmation() {
  const { id } = useParams();
  const [enrollments, setEnrollments] = useState([]);
  const [event, setEvent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [notification, setNotification] = useState({ show: false, message: '', type: '' });
  const [selectedEnrollment, setSelectedEnrollment] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem('authToken'); // Adjust based on where your token is stored
    if (token) {
      const decodedToken = jwtDecode(token);
      if (decodedToken.role !== 'admin') {
        navigate('/');
      } 
    } else {
      navigate('/');
    }
  }, [navigate]);

  useEffect(() => {
    fetchData();
  }, [id]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const [enrollmentsRes, eventRes] = await Promise.all([
        axios.get(`https://karem-tours.onrender.com/api/enrollments/event/${id}`),
        axios.get(`https://karem-tours.onrender.com/api/events/${id}`)
      ]);
      setEnrollments(enrollmentsRes.data);
      setEvent(eventRes.data);
    } catch (error) {
      showNotification('Error fetching data', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemove = async (enrollmentId) => {
    try {
      await axios.delete(`https://karem-tours.onrender.com/api/enrollments/${enrollmentId}`);
      setEnrollments(enrollments.filter(enrollment => enrollment._id !== enrollmentId));
      showNotification('Enrollment deleted successfully', 'success');
    } catch (error) {
      showNotification('Error deleting enrollment', 'error');
    }
  };

  const handleConfirm = async (enrollmentId) => {
    try {
      const enrollment = enrollments.find(e => e._id === enrollmentId);
      const newStatus = !enrollment.enrollmentStatus;
      await axios.patch(`https://karem-tours.onrender.com/api/enrollments/${enrollmentId}`, { enrollmentStatus: newStatus });
      setEnrollments(enrollments.map(enrollment => 
        enrollment._id === enrollmentId ? { ...enrollment, enrollmentStatus: newStatus } : enrollment
      ));
      showNotification(`Enrollment ${newStatus ? 'confirmed' : 'moved to pending'}`, 'success');
    } catch (error) {
      showNotification('Error updating enrollment status', 'error');
    }
  };

  const showNotification = (message, type) => {
    setNotification({ show: true, message, type });
    setTimeout(() => setNotification({ show: false, message: '', type: '' }), 3000);
  };

  const filteredEnrollments = enrollments.filter(enrollment => {
    const fullName = `${enrollment.firstName} ${enrollment.lastName}`.toLowerCase();
    const email = enrollment.email.toLowerCase();
    const searchLower = searchTerm.toLowerCase();
    return fullName.includes(searchLower) || email.includes(searchLower);
  });

  const confirmedEnrollments = filteredEnrollments.filter(e => e.enrollmentStatus);
  const pendingEnrollments = filteredEnrollments.filter(e => !e.enrollmentStatus);

  const HoverCard = ({ enrollment }) => (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 10 }}
      className="absolute left-0 right-0 top-full mt-2 bg-white rounded-xl shadow-xl border border-gray-200 p-4 z-50"
    >
      <div className="grid grid-cols-2 gap-4">
        <div>
          <h4 className="font-semibold text-gray-800 mb-2">Personal Information</h4>
          <div className="space-y-2">
            <div className="flex items-center text-gray-600">
              <FaMapMarkerAlt className="mr-2" />
              <span>{enrollment.address}</span>
            </div>
            <div className="flex items-center text-gray-600">
              <FaBirthdayCake className="mr-2" />
              <span>{enrollment.age} years old</span>
            </div>
          </div>
        </div>
        {enrollment.hasChildren && (
          <div>
            <h4 className="font-semibold text-gray-800 mb-2">Children Information</h4>
            <div className="space-y-2">
              <div className="flex items-center text-gray-600">
                <FaChild className="mr-2" />
                <span>{enrollment.numberOfChildren} children</span>
              </div>
              {enrollment.childrenAges.length > 0 && (
                <div className="text-gray-600">
                  Ages: {enrollment.childrenAges.join(', ')}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {enrollment.image && (
        <div className="mt-4">
          <img
            src={enrollment.image}
            alt={`${enrollment.firstName} ${enrollment.lastName}`}
            className="w-full h-auto rounded-lg"
          />
        </div>
      )}
    </motion.div>
  );

  const EnrollmentCard = ({ enrollment, status }) => {
    const [showHoverCard, setShowHoverCard] = useState(false);

    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        className="relative"
        onMouseEnter={() => setShowHoverCard(true)}
        onMouseLeave={() => setShowHoverCard(false)}
      >
        <div className={`bg-white rounded-xl shadow-md overflow-hidden border border-gray-200 hover:shadow-lg transition-all duration-300 ${showHoverCard ? 'ring-2 ring-red-500' : ''}`}>
          <div className={`p-4 ${status === 'confirmed' ? 'border-l-4 border-green-500' : 'border-l-4 border-yellow-500'}`}>
            <div className="flex justify-between items-start mb-4">
              <div>
                <h3 className="text-lg font-semibold text-gray-800">
                  {enrollment.firstName} {enrollment.lastName}
                </h3>
                <div className="flex items-center text-gray-600 text-sm mt-1">
                  <FaEnvelope className="mr-2" />
                  {enrollment.email}
                </div>
                <div className="flex items-center text-gray-600 text-sm mt-1">
                  <FaPhone className="mr-2" />
                  {enrollment.phone}
                </div>
              </div>
              <div className={`px-3 py-1 rounded-full text-sm ${
                status === 'confirmed' ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'
              }`}>
                {status === 'confirmed' ? (
                  <div className="flex items-center">
                    <FaUserCheck className="mr-1" />
                    Confirmed
                  </div>
                ) : (
                  <div className="flex items-center">
                    <FaUserClock className="mr-1" />
                    Pending
                  </div>
                )}
              </div>
            </div>
            
            <div className="flex justify-end space-x-2">
              {status === 'confirmed' ? (
                <>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => handleConfirm(enrollment._id)}
                    className="px-4 py-2 bg-yellow-100 text-yellow-700 rounded-lg hover:bg-yellow-200 transition-colors flex items-center"
                  >
                    <FaClock className="mr-2" />
                    Move to Pending
                  </motion.button>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => handleRemove(enrollment._id)}
                    className="px-4 py-2 bg-red-100 text-red-700 rounded-lg hover:bg-red-200 transition-colors flex items-center"
                  >
                    <FaTimes className="mr-2" />
                    Remove
                  </motion.button>
                </>
              ) : (
                <>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => handleConfirm(enrollment._id)}
                    className="px-4 py-2 bg-green-100 text-green-700 rounded-lg hover:bg-green-200 transition-colors flex items-center"
                  >
                    <FaCheck className="mr-2" />
                    Confirm
                  </motion.button>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => handleRemove(enrollment._id)}
                    className="px-4 py-2 bg-red-100 text-red-700 rounded-lg hover:bg-red-200 transition-colors flex items-center"
                  >
                    <FaTimes className="mr-2" />
                    Remove
                  </motion.button>
                </>
              )}
            </div>
          </div>
        </div>
        <AnimatePresence>
          {showHoverCard && <HoverCard enrollment={enrollment} />}
        </AnimatePresence>
      </motion.div>
    );
  };

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-red-600"></div>
      </div>
    );
  }

  return (
    <>
    <NavBar />
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-white py-8 mt-16">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Event Header */}
        {event && (
          <div className="bg-white rounded-2xl shadow-lg p-6 mb-8">
            <h1 className="text-3xl font-bold text-gray-800 mb-4">{event.title}</h1>
            <div className="flex flex-wrap gap-4 text-gray-600">
              <div className="flex items-center">
                <FaCalendarAlt className="mr-2 text-red-500" />
                {new Date(event.date).toLocaleDateString()}
              </div>
              <div className="flex items-center">
                <FaUserCheck className="mr-2 text-green-500" />
                {confirmedEnrollments.length} Confirmed
              </div>
              <div className="flex items-center">
                <FaUserClock className="mr-2 text-yellow-500" />
                {pendingEnrollments.length} Pending
              </div>
            </div>
          </div>
        )}

        {/* Search Bar */}
        <div className="bg-white rounded-xl shadow-md p-4 mb-6">
          <div className="relative">
            <input
              type="text"
              placeholder="Search by name or email..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-4 pr-10 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-red-500 focus:border-transparent"
            />
            <FaSearch className="absolute right-3 top-3 text-gray-400" />
          </div>
        </div>

        {/* Enrollment Lists */}
        <div className="grid md:grid-cols-2 gap-8">
          {/* Confirmed Enrollments */}
          <div>
            <h2 className="text-xl font-semibold text-gray-800 mb-4 flex items-center">
              <FaUserCheck className="mr-2 text-green-500" />
              Confirmed Enrollments ({confirmedEnrollments.length})
            </h2>
            <div className="space-y-4">
              <AnimatePresence>
                {confirmedEnrollments.map(enrollment => (
                  <EnrollmentCard
                    key={enrollment._id}
                    enrollment={enrollment}
                    status="confirmed"
                  />
                ))}
              </AnimatePresence>
              {confirmedEnrollments.length === 0 && (
                <div className="text-center py-8 text-gray-500">
                  No confirmed enrollments
                </div>
              )}
            </div>
          </div>

          {/* Pending Enrollments */}
          <div>
            <h2 className="text-xl font-semibold text-gray-800 mb-4 flex items-center">
              <FaUserClock className="mr-2 text-yellow-500" />
              Pending Enrollments ({pendingEnrollments.length})
            </h2>
            <div className="space-y-4">
              <AnimatePresence>
                {pendingEnrollments.map(enrollment => (
                  <EnrollmentCard
                    key={enrollment._id}
                    enrollment={enrollment}
                    status="pending"
                  />
                ))}
              </AnimatePresence>
              {pendingEnrollments.length === 0 && (
                <div className="text-center py-8 text-gray-500">
                  No pending enrollments
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Notification Toast */}
        <AnimatePresence>
          {notification.show && (
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 50 }}
              className={`fixed bottom-4 right-4 px-6 py-3 rounded-xl shadow-lg ${
                notification.type === 'success' ? 'bg-green-600' : 'bg-red-600'
              } text-white z-50`}
            >
              {notification.message}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
    <Footer />
    </>
  );
}