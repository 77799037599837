import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import {
  FaCalendarAlt,
  FaClock,
  FaDollarSign,
  FaUsers,
  FaRegClock,
  FaChevronLeft,
  FaChevronRight,
  FaMapMarkerAlt,
  FaInfoCircle,
  FaClipboardList,
  FaImage,
  FaTicketAlt,
  FaShareAlt,
} from "react-icons/fa";
import EnrollForm from "../compoenents/EnrollForm";
import NavBar from "../compoenents/NavBar";
import Footer from "../compoenents/footer";

export default function EventDetails() {
  const { id } = useParams();
  const [eventData, setEventData] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const response = await axios.get(
          `https://karem-tours.onrender.com/api/events/${id}`
        );
        setEventData(response.data);
        setLoading(false);
      } catch (error) {
        console.error(
          "Erreur:",
          error.response ? error.response.data : error.message
        );
        setLoading(false);
      }
    };

    fetchEventDetails();
  }, [id]);

  const nextImage = () => {
    setCurrentImageIndex((prev) => (prev + 1) % eventData.images.length);
  };

  const prevImage = () => {
    setCurrentImageIndex(
      (prev) => (prev - 1 + eventData.images.length) % eventData.images.length
    );
  };

  const handleShare = () => {
    const shareableLink = window.location.href;
    navigator.clipboard.writeText(shareableLink)
      .then(() => {
        alert('Lien copié dans le presse-papiers !');
      })
      .catch(err => {
        console.error('Échec de la copie : ', err);
      });
  };

  const isEventPast = eventData && new Date(eventData.endTime) < new Date();

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gradient-to-br from-red-50 to-indigo-50">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-red-600"></div>
      </div>
    );
  }

  if (!eventData) return null;

  return (
    <>
      <NavBar />
      <div className="min-h-screen bg-gradient-to-br from-red-50 to-indigo-50 mt-16">
        {/* Section Héro */}
        <div className="relative h-[60vh] overflow-hidden">
          <div className="absolute inset-0 bg-black/30 z-10"></div>
          <AnimatePresence mode="wait">
            <motion.img
              key={currentImageIndex}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              src={eventData.images[currentImageIndex]}
              alt={`Événement ${currentImageIndex + 1}`}
              className="absolute inset-0 w-full h-full object-cover"
            />
          </AnimatePresence>

          {/* Flèches de Navigation */}
          {eventData.images.length > 1 && (
            <>
              <button
                onClick={prevImage}
                className="absolute left-4 top-1/2 -translate-y-1/2 z-20 bg-white/20 backdrop-blur-sm p-3 rounded-full text-white hover:bg-white/40 transition-all"
              >
                <FaChevronLeft size={24} />
              </button>
              <button
                onClick={nextImage}
                className="absolute right-4 top-1/2 -translate-y-1/2 z-20 bg-white/20 backdrop-blur-sm p-3 rounded-full text-white hover:bg-white/40 transition-all"
              >
                <FaChevronRight size={24} />
              </button>
            </>
          )}

          {/* Contenu Héro */}
          <div className="absolute bottom-0 left-0 right-0 z-20 p-8 bg-gradient-to-t from-black/80 to-transparent">
            <div className="max-w-6xl mx-auto">
              <motion.h1
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                className="text-4xl md:text-5xl font-bold text-white mb-4"
              >
                {eventData.title}
              </motion.h1>
              <div className="flex flex-wrap gap-4 text-white">
                <div className="flex items-center">
                  <FaCalendarAlt className="mr-2" />
                  {new Date(eventData.startTime).toLocaleDateString()} <br />
                  {new Date(eventData.startTime).toLocaleTimeString()}
                </div>
                <div className="flex items-center">
                  <FaCalendarAlt className="mr-2" />
                  {new Date(eventData.endTime).toLocaleDateString()} <br />
                  {new Date(eventData.endTime).toLocaleTimeString()}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Contenu Principal */}
        <div className="max-w-6xl mx-auto px-4 py-12">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            {/* Colonne Gauche - Info Principale */}
            <div className="lg:col-span-2 space-y-8">
              {/* Carte Description */}
              <motion.div
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                className="bg-white rounded-2xl shadow-xl p-6"
              >
                <h2 className="text-2xl font-bold mb-4 flex items-center">
                  <FaInfoCircle className="mr-2 text-red-600" />
                  À Propos de Cet Événement
                </h2>
                {eventData.description.split("\n").map((paragraph, index) => (
                  <p key={index} className="text-gray-700 leading-relaxed mb-4">
                    {paragraph}
                  </p>
                ))}
              </motion.div>

              {/* Carte Détails */}
              <motion.div
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.1 }}
                className="bg-white rounded-2xl shadow-xl p-6"
              >
                <h2 className="text-2xl font-bold mb-4 flex items-center">
                  <FaClipboardList className="mr-2 text-purple-600" />
                  Détails de l'Événement
                </h2>
                {eventData.details.split("\n").map((paragraph, index) => (
                  <p key={index} className="text-gray-700 leading-relaxed mb-4">
                    {paragraph}
                  </p>
                ))}
              </motion.div>

              {/* Galerie d'Images */}
              <motion.div
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.2 }}
                className="bg-white rounded-2xl shadow-xl p-6"
              >
                <h2 className="text-2xl font-bold mb-4 flex items-center">
                  <FaImage className="mr-2 text-green-600" />
                  Galerie de Photos
                </h2>
                <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                  {eventData.images.map((image, index) => (
                    <motion.div
                      key={index}
                      whileHover={{ scale: 1.05 }}
                      className="relative aspect-square rounded-lg overflow-hidden cursor-pointer"
                      onClick={() => setSelectedImage(image)}
                    >
                      <img
                        src={image}
                        alt={`Galerie ${index + 1}`}
                        className="w-full h-full object-cover"
                      />
                    </motion.div>
                  ))}
                </div>
              </motion.div>
            </div>

            {/* Colonne Droite - Carte d'Action */}
            <div className="lg:col-span-1">
              <motion.div
                initial={{ x: 20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                className="bg-white rounded-2xl shadow-xl p-6 sticky top-4"
              >
                <div className="text-center mb-6">
                  <h3 className="text-3xl font-bold text-gray-900 mb-2">
                    {eventData.price} DT
                  </h3>
                  <p className="text-gray-600">par personne</p>
                </div>

                <div className="space-y-4 mb-6">
                  <div className="flex items-center text-gray-700">
                    <FaRegClock className="mr-3 text-red-600" />
                    <div>
                      <p className="font-semibold">Date Limite d'Inscription</p>
                      <p>
                        {new Date(
                          eventData.registrationDeadline
                        ).toLocaleDateString()}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center text-gray-700">
                    <FaUsers className="mr-3 text-red-600" />
                    <div>
                      <p className="font-semibold">Capacité</p>
                      <p>{eventData.maxParticipants} participants</p>
                    </div>
                  </div>
                </div>

                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => setShowForm(true)}
                  className={`w-full py-4 ${
                    isEventPast
                      ? "bg-gray-300 cursor-not-allowed"
                      : "bg-red-400 hover:from-red-700 hover:to-orange-200"
                  } text-white font-bold rounded-xl transition-all duration-300 shadow-lg mb-4`}
                  disabled={isEventPast}
                >
                  <FaTicketAlt className="inline-block mr-2" />
                  {isEventPast ? "Événement Terminé" : "Réservez Votre Place"}
                </motion.button>
                <button 
                  className="w-full py-4 bg-white border-2 border-gray-200 text-gray-700 font-bold rounded-xl hover:bg-gray-50 transition-all duration-300"
                  onClick={handleShare}
                >
                  <FaShareAlt className="inline-block mr-2 text-blue-500" />
                  Partager
                </button>
              </motion.div>
            </div>
          </div>
        </div>

        {/* Modal Image */}
        <AnimatePresence>
          {selectedImage && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={() => setSelectedImage(null)}
              className="fixed inset-0 bg-black/90 z-50 flex items-center justify-center p-4"
            >
              <motion.img
                initial={{ scale: 0.9 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0.9 }}
                src={selectedImage}
                alt="Événement sélectionné"
                className="max-w-full max-h-[90vh] object-contain"
              />
            </motion.div>
          )}
        </AnimatePresence>

        {/* Modal Formulaire d'Inscription */}
        <AnimatePresence>
          {showForm && !isEventPast && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4"
            >
              <motion.div
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.9, opacity: 0 }}
                className="bg-white rounded-2xl p-6 max-w-xl w-full"
              >
                <EnrollForm onClose={() => setShowForm(false)} eventId={id} />
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <Footer />
    </>
  );
}