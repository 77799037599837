import React, { useState, useEffect } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { motion } from "framer-motion";
import {
  FaUser,
  FaEnvelope,
  FaPhone,
  FaMapMarkerAlt,
  FaBirthdayCake,
  FaChild,
  FaTimes,
  FaCheck,
} from "react-icons/fa";

export default function EnrollForm({ onClose, eventId }) {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
    age: "",
    hasChildren: false,
    numberOfChildren: 0,
    childrenAges: [],
    image: null,
  });

  const [userId, setUserId] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const totalSteps = 2;

  useEffect(() => {
    const token = sessionStorage.getItem("authToken");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setUserId(decodedToken.id);
      } catch (error) {
        console.error("Échec du décodage du jeton:", error);
      }
    }
  }, []);

  const handleChange = (e) => {
    const { name, type, checked, files, value } = e.target;
    if (type === "file" && files.length > 0) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prevData) => ({
          ...prevData,
          [name]: reader.result,
        }));
      };
      reader.readAsDataURL(files[0]);
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };
  
  const handleChildrenChange = (index, value) => {
    const newAges = [...formData.childrenAges];
    newAges[index] = value;
    setFormData({
      ...formData,
      childrenAges: newAges,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const enrollmentData = {
      ...formData,
      eventId,
      hasChildren: formData.hasChildren === true,
    };

    if (userId) {
      enrollmentData.userId = userId;
    }

    try {
      const token = sessionStorage.getItem("authToken");
      await axios.post(
        "https://karem-tours.onrender.com/api/enrollments",
        enrollmentData,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : undefined,
          },
        }
      );
      onClose();
    } catch (error) {
      console.error(
        "Échec de l'enregistrement des données d'inscription:",
        error.response?.data
      );
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -20 }}
            className="space-y-4"
          >
            <h3 className="text-lg font-semibold text-gray-800 mb-4">
              Informations Personnelles
            </h3>
            <div className="relative">
              <FaUser className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
              <input
                type="text"
                name="firstName"
                placeholder="Prénom"
                value={formData.firstName}
                onChange={handleChange}
                className="w-full pl-10 pr-4 py-3 border border-gray-200 rounded-xl focus:ring-2 focus:ring-red-500 focus:border-transparent transition-all"
                required
              />
            </div>
            <div className="relative">
              <FaUser className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
              <input
                type="text"
                name="lastName"
                placeholder="Nom de famille"
                value={formData.lastName}
                onChange={handleChange}
                className="w-full pl-10 pr-4 py-3 border border-gray-200 rounded-xl focus:ring-2 focus:ring-red-500 focus:border-transparent transition-all"
                required
              />
            </div>
            <div className="relative">
              <FaEnvelope className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
              <input
                type="email"
                name="email"
                placeholder="Adresse e-mail"
                value={formData.email}
                onChange={handleChange}
                className="w-full pl-10 pr-4 py-3 border border-gray-200 rounded-xl focus:ring-2 focus:ring-red-500 focus:border-transparent transition-all"
                required
              />
            </div>

            <div class="relative">
  <label class="flex flex-col items-center justify-center w-full h-32 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-lg cursor-pointer hover:bg-gray-50 hover:border-gray-400 group">
    <div class="flex flex-col items-center justify-center pt-5 pb-6">
      <svg class="w-10 h-10 mb-3 text-gray-400 group-hover:text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
      </svg>
      <p class="mb-2 text-sm text-gray-500 dark:text-gray-400 group-hover:text-gray-600">
        <span class="font-semibold">Click to upload</span> or drag and drop
      </p>
      <p class="text-xs text-gray-500 dark:text-gray-400 group-hover:text-gray-600">
        SVG, PNG, JPG or GIF (MAX. 800x400px)
      </p>
    </div>
    <input 
      type="file" 
      name="image"
      onChange={handleChange}
      class="hidden"
      required
    />
  </label>
</div>
          </motion.div>
        );

      case 2:
        return (
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -20 }}
            className="space-y-4"
          >
            <h3 className="text-lg font-semibold text-gray-800 mb-4">
              Coordonnées & Informations Supplémentaires
            </h3>
            <div className="relative">
              <FaPhone className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
              <input
                type="tel"
                name="phone"
                placeholder="Numéro de téléphone"
                value={formData.phone}
                onChange={handleChange}
                className="w-full pl-10 pr-4 py-3 border border-gray-200 rounded-xl focus:ring-2 focus:ring-red-500 focus:border-transparent transition-all"
                required
              />
            </div>
            <div className="relative">
              <FaMapMarkerAlt className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
              <input
                type="text"
                name="address"
                placeholder="Adresse"
                value={formData.address}
                onChange={handleChange}
                className="w-full pl-10 pr-4 py-3 border border-gray-200 rounded-xl focus:ring-2 focus:ring-red-500 focus:border-transparent transition-all"
                required
              />
            </div>
            <div className="relative">
              <FaBirthdayCake className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
              <input
                type="number"
                name="age"
                placeholder="Âge"
                value={formData.age}
                onChange={handleChange}
                className="w-full pl-10 pr-4 py-3 border border-gray-200 rounded-xl focus:ring-2 focus:ring-red-500 focus:border-transparent transition-all"
                required
              />
            </div>

            <div className="bg-gray-50 p-4 rounded-xl">
              <label className="flex items-center space-x-3 cursor-pointer">
                <div className="relative">
                  <input
                    type="checkbox"
                    name="hasChildren"
                    checked={formData.hasChildren === true}
                    onChange={handleChange}
                    className="sr-only"
                  />
                  <div
                    className={`w-6 h-6 border-2 rounded-md transition-colors ${
                      formData.hasChildren === true
                        ? "bg-red-500 border-red-500"
                        : "border-gray-300"
                    }`}
                  >
                    {formData.hasChildren === true && (
                      <FaCheck className="text-white w-4 h-4 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
                    )}
                  </div>
                </div>
                <span className="text-gray-700">
                  Accompagnez-vous des enfants ?
                </span>
              </label>
            </div>

            {formData.hasChildren === true && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                className="space-y-4"
              >
                <div className="relative">
                  <FaChild className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
                  <input
                    type="number"
                    name="numberOfChildren"
                    placeholder="Nombre d'enfants"
                    value={formData.numberOfChildren}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        numberOfChildren: Number(e.target.value),
                        childrenAges: Array(Number(e.target.value)).fill(""),
                      })
                    }
                    className="w-full pl-10 pr-4 py-3 border border-gray-200 rounded-xl focus:ring-2 focus:ring-red-500 focus:border-transparent transition-all"
                  />
                </div>

                {Array.from(
                  { length: Number(formData.numberOfChildren) },
                  (_, index) => (
                    <div key={index} className="relative">
                      <FaBirthdayCake className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
                      <input
                        type="number"
                        placeholder={`Âge de l'enfant ${index + 1}`}
                        value={formData.childrenAges[index] || ""}
                        onChange={(e) =>
                          handleChildrenChange(index, e.target.value)
                        }
                        className="w-full pl-10 pr-4 py-3 border border-gray-200 rounded-xl focus:ring-2 focus:ring-red-500 focus:border-transparent transition-all"
                      />
                    </div>
                  )
                )}
              </motion.div>
            )}
          </motion.div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex justify-center items-center p-4 z-50">
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.9 }}
        className="bg-white rounded-2xl shadow-xl w-full max-w-md relative overflow-hidden"
      >
        <button
          onClick={onClose}
          className="absolute right-4 top-4 text-gray-400 hover:text-gray-600 transition-colors"
        >
          <FaTimes size={24} />
        </button>

        <div className="p-6">
          <h2 className="text-2xl font-bold text-gray-800 mb-6">
            Inscription à l'Événement
          </h2>

          {/* Barre de progression */}
          <div className="mb-8">
            <div className="h-2 bg-gray-200 rounded-full">
              <div
                className="h-full bg-red-500 rounded-full transition-all duration-300"
                style={{ width: `${(currentStep / totalSteps) * 100}%` }}
              ></div>
            </div>
            <div className="flex justify-between mt-2 text-sm text-gray-500">
              <span>Info Personnelle</span>
              <span>Coordonnées & Supplémentaire</span>
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            {renderStep()}

            <div className="flex justify-between mt-8">
              {currentStep > 1 && (
                <button
                  type="button"
                  onClick={() => setCurrentStep((step) => step - 1)}
                  className="px-6 py-2 border border-gray-200 rounded-xl text-gray-600 hover:bg-gray-50 transition-colors"
                >
                  Retour
                </button>
              )}

              {currentStep < totalSteps ? (
                <button
                  type="button"
                  onClick={() => setCurrentStep((step) => step + 1)}
                  disabled={
                    currentStep === 2 &&
                    formData.hasChildren === true &&
                    (!formData.numberOfChildren ||
                      formData.childrenAges.some((age) => age === ""))
                  }
                  className={`px-6 py-2 ${
                    currentStep === 2 &&
                    formData.hasChildren === true &&
                    (!formData.numberOfChildren ||
                      formData.childrenAges.some((age) => age === ""))
                      ? "bg-gray-300"
                      : "bg-red-600 hover:bg-red-700"
                  } text-white rounded-xl transition-colors ml-auto`}
                >
                  Suivant
                </button>
              ) : (
                <button
                  type="submit"
                  className="px-6 py-2 bg-green-600 text-white rounded-xl hover:bg-green-700 transition-colors ml-auto"
                >
                  Terminer l'inscription
                </button>
              )}
            </div>
          </form>
        </div>
      </motion.div>
    </div>
  );
}
