import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  FaEdit, 
  FaTrash, 
  FaEye, 
  FaUsers, 
  FaPlus,
  FaSearch,
  FaSortAmountDown,
  FaSortAmountUp,
  FaCalendarAlt,
  FaChartLine,
  FaClock,
  FaMapMarkerAlt
} from 'react-icons/fa';
import { jwtDecode } from 'jwt-decode';

import NavBar from '../compoenents/NavBar';
import Footer from '../compoenents/footer';

export default function AdminPage() {
  const [events, setEvents] = useState([]);
  const [enrollments, setEnrollments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');
  const [deleteConfirm, setDeleteConfirm] = useState(null);
  const [notification, setNotification] = useState({ show: false, message: '', type: '' });
  const [selectedFilter, setSelectedFilter] = useState('all');
  const [viewMode, setViewMode] = useState('grid');

  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem('authToken'); 
    if (token) {
      const decodedToken = jwtDecode(token);
      if (decodedToken.role !== 'admin') {
        navigate('/');
      } else {
        fetchEventsAndEnrollments();
      }
    } else {
      navigate('/');
    }
  }, [navigate]);

  useEffect(() => {
    fetchEventsAndEnrollments();
  }, []);

  const fetchEventsAndEnrollments = async () => {
    setIsLoading(true);
    try {
      const [eventsRes, enrollmentsRes] = await Promise.all([
        axios.get('https://karem-tours.onrender.com/api/events'),
        axios.get('https://karem-tours.onrender.com/api/enrollments')
      ]);
      setEvents(eventsRes.data);
      setEnrollments(enrollmentsRes.data);
    } catch (error) {
      showNotification('Erreur lors de la récupération des données. Veuillez réessayer.', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = (id) => {
    navigate(`/admin/edit/${id}`);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://karem-tours.onrender.com/api/events/${id}`);
      setEvents(events.filter(event => event._id !== id));
      showNotification('Événement supprimé avec succès', 'success');
      setDeleteConfirm(null);
    } catch (error) {
      showNotification('Erreur lors de la suppression de l\'événement', 'error');
    }
  };

  const handleViewEnrollments = (id) => {
    navigate(`/admin/enrollment/${id}`);
  };

  const getConfirmedCount = (eventId) => {
    return enrollments.filter(
      enrollment => enrollment.eventId === eventId && enrollment.enrollmentStatus
    ).length;
  };

  const showNotification = (message, type) => {
    setNotification({ show: true, message, type });
    setTimeout(() => setNotification({ show: false, message: '', type: '' }), 3000);
  };

  const getEventStatus = (event) => {
    const now = new Date();
    const endTime = new Date(event.endTime);
    if (endTime < now) return 'passé';
    if (getConfirmedCount(event._id) >= event.maxParticipants) return 'complet';
    return 'à venir';
  };

  const filteredEvents = events
    .filter(event => {
      const matchesSearch = event.title.toLowerCase().includes(searchTerm.toLowerCase());
      let matchesFilter = selectedFilter === 'all';
      if (selectedFilter === 'upcoming') {
        matchesFilter = getEventStatus(event) === 'à venir';
      } else if (selectedFilter === 'past') {
        matchesFilter = getEventStatus(event) === 'passé';
      } else if (selectedFilter === 'full') {
        matchesFilter = getEventStatus(event) === 'complet';
      } else if (selectedFilter === 'notCompleted') {
        matchesFilter = getConfirmedCount(event._id) < event.maxParticipants;
      }
      return matchesSearch && matchesFilter;
    })
    .sort((a, b) => {
      if (sortDirection === 'asc') {
        return a.title.localeCompare(b.title);
      }
      return b.title.localeCompare(a.title);
    });

  const EventCard = ({ event }) => {
    const status = getEventStatus(event);
    const statusColors = {
      'à venir': 'bg-green-100 text-green-800',
      'passé': 'bg-red-100 text-gray-800',
      'complet': 'bg-gray-100 text-red-800'
    };

    return (
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        className="bg-white rounded-2xl shadow-lg overflow-hidden border border-red-100 hover:shadow-xl transition-all duration-300"
      >
        <div className="relative">
          <img
            src={event.images?.[0] || 'https://via.placeholder.com/400x200'}
            alt={event.title}
            className="w-full h-48 object-cover"
          />
          <div className={`absolute top-4 right-4 px-3 py-1 rounded-full ${statusColors[status]}`}>
            {status.charAt(0).toUpperCase() + status.slice(1)}
          </div>
        </div>
        <div className="p-6">
          <h3 className="text-xl font-semibold text-gray-800 mb-2">{event.title}</h3>
          <div className="space-y-2 mb-4">
            <div className="flex items-center text-gray-600">
              <FaCalendarAlt className="mr-2 text-red-500" />
              {new Date(event.startTime).toLocaleDateString()}
            </div>
           
            <div className="flex items-center text-gray-600">
              <FaUsers className="mr-2 text-red-500" />
              {getConfirmedCount(event._id)}/{event.maxParticipants} Participants
            </div>
          </div>
          <div className="flex justify-between items-center pt-4 border-t border-red-100">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => handleViewEnrollments(event._id)}
              className="px-4 py-2 bg-red-50 text-red-600 rounded-xl hover:bg-red-100 transition-colors"
            >
              Voir les détails
            </motion.button>
            <div className="flex space-x-2">
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={() => handleEdit(event._id)}
                className="p-2 text-green-600 hover:bg-green-50 rounded-full transition-colors"
              >
                <FaEdit />
              </motion.button>
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={() => setDeleteConfirm(event._id)}
                className="p-2 text-red-600 hover:bg-red-50 rounded-full transition-colors"
              >
                <FaTrash />
              </motion.button>
            </div>
          </div>
        </div>
      </motion.div>
    );
  };

  return (
    <>
      <NavBar />
      <div className="min-h-screen bg-gradient-to-br from-red-50 to-white py-8 mt-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Statistiques du tableau de bord */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
            <motion.div
              whileHover={{ y: -5 }}
              className="bg-white p-6 rounded-2xl shadow-lg border border-red-100"
            >
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-gray-500">Total des événements</p>
                  <h4 className="text-2xl font-bold text-gray-800">{events.length}</h4>
                </div>
                <div className="p-3 bg-red-100 rounded-full">
                  <FaCalendarAlt className="text-xl text-red-600" />
                </div>
              </div>
            </motion.div>

            <motion.div
              whileHover={{ y: -5 }}
              className="bg-white p-6 rounded-2xl shadow-lg border border-red-100"
            >
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-gray-500">Total des inscriptions</p>
                  <h4 className="text-2xl font-bold text-gray-800">{enrollments.length}</h4>
                </div>
                <div className="p-3 bg-red-100 rounded-full">
                  <FaUsers className="text-xl text-red-600" />
                </div>
              </div>
            </motion.div>

            <motion.div
              whileHover={{ y: -5 }}
              className="bg-white p-6 rounded-2xl shadow-lg border border-red-100"
            >
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-gray-500">Événements actifs</p>
                  <h4 className="text-2xl font-bold text-gray-800">
                    {events.filter(e => new Date(e.endTime) > new Date()).length}
                  </h4>
                </div>
                <div className="p-3 bg-red-100 rounded-full">
                  <FaChartLine className="text-xl text-red-600" />
                </div>
              </div>
            </motion.div>
          </div>

          {/* Panneau de contrôle */}
          <div className="bg-white rounded-2xl shadow-lg p-6 mb-8 border border-red-100">
            <div className="flex flex-col md:flex-row justify-between items-center mb-6">
              <div className="flex items-center mb-4 md:mb-0">
                <h2 className="text-3xl font-bold text-gray-800">Gestion des événements</h2>
              </div>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => navigate('/admin/add-event')}
                className="flex items-center px-6 py-3 bg-gradient-to-r from-red-600 to-red-500 text-white rounded-xl hover:from-red-700 hover:to-red-600 transition-all shadow-md"
              >
                <FaPlus className="mr-2" />
                Créer un événement
              </motion.button>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              <div className="relative">
                <FaSearch className="absolute left-3 top-3 text-gray-400" />
                <input
                  type="text"
                  placeholder="Rechercher des événements..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full pl-10 pr-4 py-2 border border-red-100 rounded-xl focus:ring-2 focus:ring-red-500 focus:border-transparent"
                />
              </div>

              <select
                value={selectedFilter}
                onChange={(e) => setSelectedFilter(e.target.value)}
                className="w-full py-2 px-4 border border-red-100 rounded-xl focus:ring-2 focus:ring-red-500 focus:border-transparent"
              >
                <option value="all">Tous les événements</option>
                <option value="upcoming">Événements à venir</option>
                <option value="past">Événements passés</option>
                <option value="full">Événements complets</option>
                <option value="notCompleted">Événements non complets</option>
              </select>

              <div className="flex justify-end space-x-4">
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => setSortDirection(prev => prev === 'asc' ? 'desc' : 'asc')}
                  className="flex items-center px-4 py-2 bg-red-50 text-red-600 rounded-xl hover:bg-red-100 transition-colors"
                >
                  {sortDirection === 'asc' ? <FaSortAmountUp className="mr-2" /> : <FaSortAmountDown className="mr-2" />}
                  Trier
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => setViewMode(prev => prev === 'grid' ? 'list' : 'grid')}
                  className="flex items-center px-4 py-2 bg-red-50 text-red-600 rounded-xl hover:bg-red-100 transition-colors"
                >
                  {viewMode === 'grid' ? 'Vue Liste' : 'Vue Grille'}
                </motion.button>
              </div>
            </div>
          </div>

          {/* Affichage des événements */}
          {isLoading ? (
            <div className="flex items-center justify-center h-64">
              <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-red-600"></div>
            </div>
          ) : filteredEvents.length === 0 ? (
            <div className="text-center py-12">
              <h3 className="text-xl text-gray-600">Aucun événement trouvé</h3>
            </div>
          ) : (
            <div className={`grid ${viewMode === 'grid' ? 'grid-cols-1 md:grid-cols-2 lg:grid-cols-3' : 'grid-cols-1'} gap-6`}>
              {filteredEvents.map(event => (
                <EventCard key={event._id} event={event} />
              ))}
            </div>
          )}

          {/* Modal de confirmation de suppression */}
          <AnimatePresence>
            {deleteConfirm && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
              >
                <motion.div
                  initial={{ scale: 0.95, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  exit={{ scale: 0.95, opacity: 0 }}
                  className="bg-white rounded-2xl p-6 max-w-md mx-4 shadow-xl"
                >
                  <h3 className="text-2xl font-bold text-gray-800 mb-4">Confirmer la suppression</h3>
                  <p className="text-gray-600 mb-6">
                    Êtes-vous sûr de vouloir supprimer cet événement ? Cette action est irréversible.
                  </p>
                  <div className="flex justify-end space-x-4">
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={() => setDeleteConfirm(null)}
                      className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-xl transition-colors"
                    >
                      Annuler
                    </motion.button>
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={() => handleDelete(deleteConfirm)}
                      className="px-4 py-2 bg-red-600 text-white rounded-xl hover:bg-red-700 transition-colors"
                    >
                      Supprimer
                    </motion.button>
                  </div>
                </motion.div>
              </motion.div>
            )}
          </AnimatePresence>

          {/* Notification Toast */}
          <AnimatePresence>
            {notification.show && (
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 50 }}
                className={`fixed bottom-4 right-4 px-6 py-3 rounded-xl shadow-lg ${
                  notification.type === 'success' ? 'bg-green-600' : 'bg-red-600'
                } text-white`}
              >
                {notification.message}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
      <Footer />
    </>
  );
}