import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, useAnimation, useScroll, useTransform } from 'framer-motion';
import { FaPlaneDeparture, FaCalendarCheck, FaGlobeAmericas, FaStar, FaQuoteLeft, FaArrowRight } from 'react-icons/fa';
import NavBar from '../compoenents/NavBar';
import Footer from '../compoenents/footer';

export default function LandingPage() {
  const controls = useAnimation();
  const { scrollY } = useScroll();
  
  const opacity = useTransform(scrollY, [0, 300], [1, 0]);
  const scale = useTransform(scrollY, [0, 300], [1, 0.8]);

  useEffect(() => {
    controls.start({ opacity: 1, y: 0 });
  }, [controls]);

  const staggerContainer = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3
      }
    }
  };

  const fadeInUp = {
    hidden: { opacity: 0, y: 60 },
    show: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.8,
        ease: [0.6, -0.05, 0.01, 0.99]
      }
    }
  };

  return (
    <>
    <NavBar />
    <div className="bg-gray-50">
      
      {/* Hero Section */}
      <section className="relative min-h-screen flex items-center overflow-hidden">
        <motion.div 
          className="absolute inset-0 bg-gradient-to-r from-blue-900/90 to-blue-200/70 z-20"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1.5 }}
        />
        <motion.div 
          className="absolute inset-0 bg-cover bg-center"
          style={{ 
            backgroundImage: 'url(/images/hero-image.jpg)',
            backgroundAttachment: 'fixed'
          }}
          initial={{ scale: 1.2 }}
          animate={{ scale: 1 }}
          transition={{ duration: 2 }}
        />
        
        <div className="relative container mx-auto px-4 sm:px-6 lg:px-8 z-20">
          <motion.div 
            className="max-w-4xl"
            variants={staggerContainer}
            initial="hidden"
            animate="show"
          >
            <motion.h1 
              className="text-4xl md:text-6xl lg:text-7xl font-bold text-white mb-8 leading-tight"
              variants={fadeInUp}
            >
              Votre voyage vers
              <span className="block bg-gradient-to-r from-red-400 to-red-600 text-transparent bg-clip-text">
                des aventures extraordinaires
              </span>
            </motion.h1>
            <motion.p 
              className="text-lg md:text-xl text-gray-200 mb-10 leading-relaxed max-w-2xl"
              variants={fadeInUp}
            >
              Vivez des voyages de luxe redéfinis avec nos services premium et destinations exclusives.
            </motion.p>
            <motion.div 
              className="flex flex-col sm:flex-row gap-6 items-center"
              variants={fadeInUp}
            >
              <Link to="/catalogue" className="w-full sm:w-auto">
                <button className="w-full sm:w-auto bg-red-600 hover:bg-red-700 text-white px-8 py-4 rounded-lg font-semibold transition duration-300 ease-out">
                  Commencez à explorer
                </button>
              </Link>
              <Link to="/about" className="w-full sm:w-auto">
                <button className="w-full sm:w-auto flex items-center justify-center gap-4 text-white hover:text-red-400 transition duration-300">
                  <span className="w-12 h-12 rounded-full bg-white/20 flex items-center justify-center backdrop-blur-sm">
                    <FaArrowRight className="ml-1" />
                  </span>
                  En savoir plus
                </button>
              </Link>
            </motion.div>
          </motion.div>
        </div>
      </section>

      {/* Stats Section */}
      <motion.section 
        className="py-16 bg-white"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
      >
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            {[
              { number: "20+", label: "Destinations" },
              { number: "700+", label: "Voyageurs Satisfaits" },
              { number: "99%", label: "Taux de Satisfaction" },
              { number: "3+", label: "Années d'Expérience" }
            ].map((stat, index) => (
              <motion.div
                key={index}
                className="text-center"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.2 }}
              >
                <h3 className="text-3xl md:text-4xl font-bold text-red-600 mb-2">{stat.number}</h3>
                <p className="text-gray-600 text-sm md:text-base">{stat.label}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </motion.section>

      {/* Services Section */}
      <section className="py-20 md:py-32 px-4 sm:px-6 lg:px-8 bg-gray-50">
        <div className="container mx-auto">
          <motion.div 
            className="text-center mb-16 md:mb-20"
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            <span className="text-red-600 font-medium tracking-wider">NOS SERVICES</span>
            <h2 className="text-3xl md:text-5xl font-bold text-gray-900 mt-4 mb-6">Expériences de Voyage de Luxe</h2>
            <div className="w-24 h-1 bg-red-600 mx-auto"></div>
          </motion.div>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[
              {
                icon: <FaCalendarCheck />,
                title: "Expériences Curatées",
                description: "Accès exclusif à des événements uniques ."
              },
              {
                icon: <FaGlobeAmericas />,
                title: "Expéditions de Luxe",
                description: "Voyages soigneusement conçus avec hébergements ."
              },
              {
                icon: <FaPlaneDeparture />,
                title: "Bon Transport ",
                description: "Arrangements de voyage en bon transport."
              }
            ].map((service, index) => (
              <motion.div
                key={index}
                className="group bg-white rounded-xl p-8 shadow-lg hover:shadow-xl transition-all duration-500"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.2 }}
                whileHover={{ y: -5 }}
              >
                <div className="text-red-600 text-3xl mb-6">
                  {service.icon}
                </div>
                <h3 className="text-xl font-bold text-gray-900 mb-4">{service.title}</h3>
                <p className="text-gray-600 leading-relaxed mb-6">{service.description}</p>
               
              </motion.div>
            ))}
          </div>
        </div>
      </section>
{/* Featured Destinations */}
<section className="py-20 md:py-32 bg-white">
  <div className="container mx-auto px-4 sm:px-6 lg:px-8">
    <motion.div 
      className="text-center mb-16 md:mb-20"
      initial={{ opacity: 0, y: 30 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
    >
      <span className="text-red-600 font-medium tracking-wider">DESTINATIONS</span>
      <h2 className="text-3xl md:text-5xl font-bold text-gray-900 mt-4 mb-6">Lieux en Vedette</h2>
      <div className="w-24 h-1 bg-red-600 mx-auto"></div>
    </motion.div>

    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
      {[
        { name: 'Espagne', rating: 4.9, price: '2 999 tnd', image: '/spain.jpg' },
        { name: 'Italie', rating: 4.8, price: '2 599 tnd', image: '/italy.jpg' },
        { name: 'Istanbul', rating: 4.9, price: '1 799 tnd', image: '/istanbul.jpg' }
      ].map((destination, index) => (
        <motion.div
          key={index}
          className="group relative h-[400px] overflow-hidden rounded-xl shadow-lg"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ delay: index * 0.2 }}
          whileHover={{ y: -5 }}
        >
          <img
            src={destination.image}
            alt={destination.name}
            className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-700"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black/80 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500">
            <div className="absolute bottom-0 p-6 w-full">
              <h3 className="text-2xl font-bold text-white mb-2">{destination.name}</h3>
              <div className="flex items-center text-yellow-400 mb-3">
                {[...Array(5)].map((_, i) => (
                  <FaStar key={i} className={`${i < Math.floor(destination.rating) ? 'text-yellow-400' : 'text-gray-400'} mr-1`} />
                ))}
                <span className="ml-2 text-white">{destination.rating}</span>
              </div>
              <p className="text-lg text-white">À partir de {destination.price}</p>
            </div>
          </div>
        </motion.div>
      ))}
    </div>
  </div>
</section>

      {/* Testimonials */}
      <section className="py-20 md:py-32 bg-gray-50">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div 
            className="text-center mb-16 md:mb-20"
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            <span className="text-red-600 font-medium tracking-wider">TÉMOIGNAGES</span>
            <h2 className="text-3xl md:text-5xl font-bold text-gray-900 mt-4 mb-6">Ce Que Disent Nos Clients</h2>
            <div className="w-24 h-1 bg-red-600 mx-auto"></div>
          </motion.div>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[
              { 
                text: "Une expérience absolument magnifique qui a dépassé toutes les attentes.",
                author: "Iyed Ben Mansour",
                role: "Voyageur Exécutif",
                image: "/iyed.jpg"
              },
              {
                text: "Planification et exécution impeccables. Chaque moment était parfaitement organisé.",
                author: "Rami Lafi",
                role: "Aventurier Passionné",
                image: "/rami.png"
              },
              {
                text: "L'expérience de voyage la plus luxueuse que j'aie jamais eue.",
                author: "Karim Khaladi",
                role: "Explorateur de Luxe",
                image: "/karim.jpg"
              }
            ].map((testimonial, index) => (
              <motion.div
                key={index}
                className="bg-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-300"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.2 }}
                whileHover={{ y: -5 }}
              >
                <FaQuoteLeft className="text-red-400 text-2xl mb-4" />
                <p className="text-gray-600 mb-6 leading-relaxed italic">{testimonial.text}</p>
                <div className="flex items-center">
                  <img
                    src={testimonial.image}
                    alt={testimonial.author}
                    className="w-12 h-12 rounded-full object-cover mr-4"
                  />
                  <div>
                    <p className="font-bold text-gray-900">{testimonial.author}</p>
                    <p className="text-red-600 text-sm">{testimonial.role}</p>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      <Footer />
    </div>
    </>
  );
}