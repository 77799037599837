import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { FaCalendarAlt, FaClock, FaDollarSign, FaMapMarkerAlt, 
         FaChevronRight, FaChevronLeft, FaPlane, FaHotel, 
         FaUsers, FaUmbrellaBeach, FaHeart, FaSearch, FaThList, FaTh, 
         FaSortAmountUp, FaSortAmountDown } from 'react-icons/fa';
import NavBar from '../compoenents/NavBar';
import Footer from '../compoenents/footer';

export default function Home() {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [imageIndexes, setImageIndexes] = useState({});
  const [favorites, setFavorites] = useState(new Set());
  const [searchTerm, setSearchTerm] = useState('');
  const [viewMode, setViewMode] = useState('grid');
  const [sortDirection, setSortDirection] = useState('asc');
  const [selectedFilter, setSelectedFilter] = useState('all');

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get('https://karem-tours.onrender.com/api/events');
        setEvents(response.data);
        const indexes = {};
        response.data.forEach(event => {
          indexes[event._id] = 0;
        });
        setImageIndexes(indexes);
        setLoading(false);
      } catch (error) {
        console.error('Erreur lors de la récupération des événements:', error);
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  const nextImage = (eventId, maxLength, e) => {
    e.stopPropagation();
    setImageIndexes(prev => ({
      ...prev,
      [eventId]: (prev[eventId] + 1) % maxLength
    }));
  };

  const prevImage = (eventId, maxLength, e) => {
    e.stopPropagation();
    setImageIndexes(prev => ({
      ...prev,
      [eventId]: (prev[eventId] - 1 + maxLength) % maxLength
    }));
  };

  const toggleFavorite = (eventId, e) => {
    e.preventDefault();
    setFavorites(prev => {
      const newFavorites = new Set(prev);
      if (newFavorites.has(eventId)) {
        newFavorites.delete(eventId);
      } else {
        newFavorites.add(eventId);
      }
      return newFavorites;
    });
  };

  const getEventTypeIcon = (eventType) => {
    switch (eventType?.toLowerCase()) {
      case 'tour': return <FaPlane className="text-blue-500" />;
      case 'hotel': return <FaHotel className="text-purple-500" />;
      case 'beach': return <FaUmbrellaBeach className="text-yellow-500" />;
      default: return <FaUsers className="text-green-500" />;
    }
  };

  const filterEvents = () => {
    const now = new Date();
    let filteredEvents = events.filter(event => {
      const endDate = new Date(event.endTime);
      const matchesFilter = 
        selectedFilter === 'all' ||
        (selectedFilter === 'upcoming' && endDate >= now) ||
        (selectedFilter === 'past' && endDate < now);

      const matchesSearch = event.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                            event.description.toLowerCase().includes(searchTerm.toLowerCase());

      return matchesFilter && matchesSearch;
    });

    filteredEvents.sort((a, b) => {
      return sortDirection === 'asc'
        ? new Date(a.startTime) - new Date(b.startTime)
        : new Date(b.startTime) - new Date(a.startTime);
    });

    return filteredEvents;
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gradient-to-br from-red-50 to-indigo-50">
        <div className="relative w-24 h-24">
          <div className="absolute top-0 left-0 w-full h-full border-4 border-red-500 rounded-full animate-ping"></div>
          <div className="absolute top-0 left-0 w-full h-full border-4 border-red-500 rounded-full animate-pulse"></div>
        </div>
      </div>
    );
  }

  return (
    <>
      <NavBar />
      <div className="min-h-screen bg-gradient-to-br from-red-50 to-indigo-50 py-12 px-4 sm:px-6 lg:px-8 mt-16">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="max-w-7xl mx-auto mb-12"
        >
          <h1 className="text-5xl font-extrabold text-gray-900 mb-8 text-center">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-red-600 to-indigo-600">
              Découvrez des Expériences Incroyables
            </span>
          </h1>

          {/* Panneau de Contrôle */}
          <div className="bg-white rounded-2xl shadow-lg p-6 mb-8 border border-red-100">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              <div className="relative">
                <FaSearch className="absolute left-3 top-3 text-gray-400" />
                <input
                  type="text"
                  placeholder="Rechercher des événements..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full pl-10 pr-4 py-2 border border-red-100 rounded-xl focus:ring-2 focus:ring-red-500 focus:border-transparent"
                />
              </div>

              <select
                value={selectedFilter}
                onChange={(e) => setSelectedFilter(e.target.value)}
                className="w-full py-2 px-4 border border-red-100 rounded-xl focus:ring-2 focus:ring-red-500 focus:border-transparent"
              >
                <option value="all">Tous les Événements</option>
                <option value="upcoming">Événements à Venir</option>
                <option value="past">Événements Passés</option>
              </select>

              <div className="flex justify-end space-x-4">
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => setSortDirection(prev => prev === 'asc' ? 'desc' : 'asc')}
                  className="flex items-center px-4 py-2 bg-red-50 text-red-600 rounded-xl hover:bg-red-100 transition-colors"
                >
                  {sortDirection === 'asc' ? <FaSortAmountUp className="mr-2" /> : <FaSortAmountDown className="mr-2" />}
                  Trier
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => setViewMode(prev => prev === 'grid' ? 'list' : 'grid')}
                  className="flex items-center px-4 py-2 bg-red-50 text-red-600 rounded-xl hover:bg-red-100 transition-colors"
                >
                  {viewMode === 'grid' ? 'Vue Liste' : 'Vue Grille'}
                </motion.button>
              </div>
            </div>
          </div>
        </motion.div>

        <div className="max-w-7xl mx-auto">
          <div className={viewMode === 'grid' ? "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8" : "space-y-4"}>
            {filterEvents().map(event => (
              <motion.div
                key={event._id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                whileHover={{ y: -5 }}
                className="bg-white rounded-2xl shadow-xl overflow-hidden transform transition-all duration-300 hover:shadow-2xl"
              >
                <div className="relative h-72">
                  <AnimatePresence mode='wait'>
                    <motion.img
                      key={imageIndexes[event._id]}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      src={event.images[imageIndexes[event._id]]}
                      alt={event.title}
                      className="w-full h-full object-cover"
                    />
                  </AnimatePresence>

                  {event.images.length > 1 && (
                    <>
                      <button
                        onClick={(e) => prevImage(event._id, event.images.length, e)}
                        className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 p-3 rounded-full text-white hover:bg-opacity-75 transition-all"
                      >
                        <FaChevronLeft />
                      </button>
                      <button
                        onClick={(e) => nextImage(event._id, event.images.length, e)}
                        className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 p-3 rounded-full text-white hover:bg-opacity-75 transition-all"
                      >
                        <FaChevronRight />
                      </button>
                    </>
                  )}

                  <div className="absolute top-4 left-4 bg-white px-4 py-2 rounded-full shadow-lg">
                    <div className="flex items-center space-x-2">
                      {getEventTypeIcon(event.type)}
                      <span className="text-sm font-bold text-gray-700">
                        {event.type || 'Événement'}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="p-6">
                  <div className="flex justify-between items-start mb-4">
                    <h2 className="text-2xl font-bold text-gray-900">{event.title}</h2>
                    <span className="flex items-center text-emerald-600 font-bold text-2xl">
                    
                      {event.price} DT
                    </span>
                  </div>

                  <p className="text-gray-600 mb-4 line-clamp-2">
                    {event.description}
                  </p>

                  <div className="space-y-3 mb-6">
                    <div className="flex items-center text-gray-600">
                      <FaCalendarAlt className="mr-2 text-red-500" />
                      <span>{new Date(event.startTime).toLocaleDateString()}</span>
                    </div>
                    <div className="flex items-center text-gray-600">
                      <FaClock className="mr-2 text-red-500" />
                      <span>{new Date(event.startTime).toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit'
                      })}</span>
                    </div>
                    {event.location && (
                      <div className="flex items-center text-gray-600">
                        <FaMapMarkerAlt className="mr-2 text-red-500" />
                        <span>{event.location}</span>
                      </div>
                    )}
                  </div>

                  <Link
                    to={`/eventdetails/${event._id}`}
                    className="block w-full text-center bg-gradient-to-r from-red-500 to-indigo-500 text-white py-3 rounded-xl hover:from-red-600 hover:to-indigo-600 transition-all duration-300 font-bold shadow-md hover:shadow-xl transform hover:scale-105"
                  >
                    Explorer Maintenant
                  </Link>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}