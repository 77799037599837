import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { jwtDecode } from 'jwt-decode';

const NavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };

    const checkAuthToken = () => {
      const token = sessionStorage.getItem('authToken');
      if (token) {
        setIsLoggedIn(true);
        try {
          const decodedToken = jwtDecode(token);
          setIsAdmin(decodedToken.role === 'admin');
        } catch (error) {
          console.error("Impossible de décoder le token :", error);
          setIsAdmin(false);
        }
      } else {
        setIsLoggedIn(false);
        setIsAdmin(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    checkAuthToken();
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleDisconnect = () => {
    sessionStorage.removeItem('authToken');
    setIsLoggedIn(false);
    setIsAdmin(false);
  };

  const navItems = [
    { name: 'Accueil', href: '/' },
    { name: 'À Propos', href: '/about' },
    { name: 'Événements', href: '/catalogue' },
    ...(isLoggedIn
      ? [
          ...(isAdmin ? [{ name: 'Admin', href: '/admin' }] : []),
          ...(isAdmin ? [{ name: 'Gestion Admin', href: '/admin/set-admin' }] : []),
          { name: 'Profil', href: '/profile' },
          { name: 'Déconnexion', href: '/login', onClick: handleDisconnect },
        ]
      : [
          { name: 'Connexion', href: '/login' },
          { name: 'Inscription', href: '/register' },
        ]),
  ];

  const textColor = isHomePage && !isScrolled ? 'text-black' : 'text-red-600';
  const hoverTextColor = isHomePage && !isScrolled ? 'hover:text-gray-200' : 'hover:text-black';

  return (
    <motion.nav
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className={`fixed top-0 left-0 w-full z-50 transition-all duration-300 ${isScrolled || !isHomePage ? 'bg-red-50 shadow-md' : 'bg-transparent'}`}
    >
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between py-4">
          <Link to="/" className="flex items-center space-x-2">
            <div className="w-12 h-12 rounded-full bg-white border-2 border-black flex items-center justify-center">
              <img
                src="/logo.png"
                alt="Logo"
                className="w-15 h-15"
              />
            </div>
            <span className={`text-xl font-bold ${textColor}`}>Société karmini services et sponsoring </span>
          </Link>
          <ul className="hidden md:flex space-x-8">
            {navItems.map((item) => (
              <li key={item.name}>
                <Link
                  to={item.href}
                  className={`text-sm font-medium ${textColor} ${hoverTextColor} transition-colors duration-300`}
                  onClick={item.onClick}
                >
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
          <div className="md:hidden">
            <button
              onClick={toggleMenu}
              className={`${textColor} focus:outline-none transition-transform duration-300 ${isMenuOpen ? 'rotate-90' : ''}`}
              aria-label="Toggle navigation"
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div className={`fixed inset-0 bg-black bg-opacity-50 z-40 md:hidden transition-opacity duration-300 ${isMenuOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`} onClick={toggleMenu}></div>
      <div className={`fixed top-0 right-0 w-64 h-full bg-white shadow-lg z-50 transform transition-transform duration-300 ease-in-out ${isMenuOpen ? 'translate-x-0' : 'translate-x-full'}`}>
        <div className="flex justify-end p-4">
          <button onClick={toggleMenu} className="text-gray-500 hover:text-gray-700 focus:outline-none">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <ul className="flex flex-col space-y-4 p-4">
          {navItems.map((item) => (
            <li key={item.name}>
              <Link
                to={item.href}
                className="block py-2 px-4 text-gray-800 hover:bg-blue-500 hover:text-white rounded transition-colors duration-300"
                onClick={(e) => {
                  if (item.onClick) {
                    e.preventDefault();
                    item.onClick();
                  }
                  toggleMenu();
                }}
              >
                {item.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </motion.nav>
  );
};

export default NavBar;