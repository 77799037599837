import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { FaEnvelope, FaPhone, FaSearch, FaUserShield, FaUser, FaSpinner, FaEllipsisV, FaTrash } from 'react-icons/fa';
import NavBar from '../compoenents/NavBar';
import Footer from '../compoenents/footer';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import CSS

export default function SetAdmin() {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState('all');
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(null);
  const [stats, setStats] = useState({
    adminCount: 0,
    userCount: 0
  });

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('https://karem-tours.onrender.com/api/users/');
        setUsers(response.data);
        
        const adminCount = response.data.filter(user => user.role === 'admin').length;
        const userCount = response.data.filter(user => user.role === 'user').length;
        
        setStats({
          adminCount,
          userCount
        });
      } catch (error) {
        toast.error('Échec du chargement des utilisateurs');
        console.error('Erreur lors du chargement des utilisateurs:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchUsers();
  }, []);

  const toggleRole = async (userId, currentRole) => {
    setUpdating(userId);
    const newRole = currentRole === 'admin' ? 'user' : 'admin';
    try {
      await axios.patch(`https://karem-tours.onrender.com/api/users/${userId}`, { role: newRole });
      const updatedUsers = users.map(user => 
        user._id === userId ? { ...user, role: newRole } : user
      );
      setUsers(updatedUsers);
      
      const adminCount = updatedUsers.filter(user => user.role === 'admin').length;
      const userCount = updatedUsers.filter(user => user.role === 'user').length;
      setStats({
        adminCount,
        userCount
      });
      
      toast.success(`Utilisateur ${newRole === 'admin' ? 'promu' : 'rétrogradé'} avec succès`);
    } catch (error) {
      toast.error('Échec de la mise à jour du rôle utilisateur');
      console.error('Erreur lors de la mise à jour du rôle utilisateur:', error);
    } finally {
      setUpdating(null);
    }
  };

  const deleteUser = (userId) => {
    confirmAlert({
      title: 'Confirmer la suppression',
      message: 'Êtes-vous sûr de vouloir supprimer cet utilisateur ?',
      buttons: [
        {
          label: 'Oui',
          onClick: () => performDelete(userId)
        },
        {
          label: 'Non'
        }
      ],
      overlayClassName: "custom-overlay",
      className: "custom-ui"
    });
  };

  const performDelete = async (userId) => {
    setUpdating(userId);
    try {
      await axios.delete(`https://karem-tours.onrender.com/api/users/${userId}`);
      const updatedUsers = users.filter(user => user._id !== userId);
      setUsers(updatedUsers);
      
      const adminCount = updatedUsers.filter(user => user.role === 'admin').length;
      const userCount = updatedUsers.filter(user => user.role === 'user').length;
      setStats({
        adminCount,
        userCount
      });
      
      toast.success('Utilisateur supprimé avec succès');
    } catch (error) {
      toast.error('Échec de la suppression de l\'utilisateur');
      console.error('Erreur lors de la suppression de l\'utilisateur:', error);
    } finally {
      setUpdating(null);
    }
  };

  const StatCard = ({ icon: Icon, title, value, gradient }) => (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      whileHover={{ y: -5, transition: { duration: 0.2 } }}
      className={`relative overflow-hidden rounded-xl shadow-lg ${gradient} p-6`}
    >
      <div className="relative z-10 flex items-center justify-between">
        <div>
          <p className="text-sm text-white/80 mb-1 font-medium">{title}</p>
          <p className="text-3xl font-bold text-white">{value.toLocaleString()}</p>
        </div>
        <div className="p-3 rounded-lg bg-white/20 backdrop-blur-sm">
          <Icon className="text-2xl text-white" />
        </div>
      </div>
      <div className="absolute top-0 right-0 w-32 h-32 transform translate-x-16 -translate-y-16 rotate-12">
        <Icon className="w-full h-full opacity-10 text-white" />
      </div>
    </motion.div>
  );

  const filteredUsers = users.filter(user => {
    const matchesSearch = user.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                         user.email.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesRole = filter === 'all' || user.role === filter;
    return matchesSearch && matchesRole;
  });

  const UserCard = ({ user }) => (
    <motion.div
      layout
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.95 }}
      whileHover={{ scale: 1.02 }}
      className="bg-white rounded-xl shadow-lg overflow-hidden border border-gray-200 hover:shadow-xl transition-all duration-300"
    >
      <div className="p-6">
        <div className="flex justify-between items-start">
          <div className="flex-grow">
            <div className="flex items-center space-x-3">
              <div className={`w-12 h-12 rounded-full flex items-center justify-center ${
                user.role === 'admin' ? 'bg-green-100' : 'bg-blue-100'
              }`}>
                {user.role === 'admin' ? 
                  <FaUserShield className="text-xl text-green-600" /> : 
                  <FaUser className="text-xl text-blue-600" />
                }
              </div>
              <div>
                <h3 className="font-bold text-xl text-gray-800">{user.fullName}</h3>
                <span className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium mt-1
                  ${user.role === 'admin' ? 'bg-green-100 text-green-800' : 'bg-blue-100 text-blue-800'}`}>
                  {user.role.charAt(0).toUpperCase() + user.role.slice(1)}
                </span>
              </div>
            </div>
            <div className="mt-4 space-y-3">
              <div className="flex items-center text-gray-600 hover:text-gray-800 transition-colors">
                <FaEnvelope className="mr-2 text-gray-400" />
                <a href={`mailto:${user.email}`} className="hover:underline">{user.email}</a>
              </div>
              <div className="flex items-center text-gray-600">
                <FaPhone className="mr-2 text-gray-400" />
                <a href={`tel:${user.phoneNumber}`} className="hover:underline">{user.phoneNumber}</a>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-end space-y-2">
            <button
              onClick={() => toggleRole(user._id, user.role)}
              disabled={updating === user._id}
              className={`px-4 py-2 rounded-lg transition-all duration-300 transform hover:scale-105
                ${user.role === 'admin' 
                  ? 'bg-gradient-to-r from-red-500 to-red-600 hover:from-red-600 hover:to-red-700' 
                  : 'bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700'
                } text-white shadow-md hover:shadow-lg disabled:opacity-50 disabled:cursor-not-allowed flex items-center space-x-2`}
            >
              {updating === user._id ? (
                <FaSpinner className="animate-spin" />
              ) : (
                <span>{user.role === 'admin' ? 'Rétrograder à utilisateur' : 'Promouvoir à admin'}</span>
              )}
            </button>
            <button
              onClick={() => deleteUser(user._id)}
              disabled={updating === user._id}
              className="px-3 py-2 rounded-full transition-colors hover:bg-red-100 text-red-600"
            >
              <FaTrash />
            </button>
            <button className="p-2 hover:bg-gray-100 rounded-full transition-colors">
              <FaEllipsisV className="text-gray-400" />
            </button>
          </div>
        </div>
      </div>
    </motion.div>
  );

  return (
    <>
      <NavBar />
      <div className="min-h-screen bg-gradient-to-br from-gray-50 to-blue-50 py-12 mt-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
            <StatCard 
              icon={FaUserShield}
              title="Administrateurs"
              value={stats.adminCount}
              gradient="bg-gradient-to-r from-green-500 to-green-600"
            />
            <StatCard 
              icon={FaUser}
              title="Utilisateurs réguliers"
              value={stats.userCount}
              gradient="bg-gradient-to-r from-blue-500 to-blue-600"
            />
          </div>

          <div className="bg-white rounded-2xl shadow-lg p-6 mb-8">
            <div className="relative">
              <FaSearch className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                type="text"
                placeholder="Rechercher par nom ou email..."
                className="w-full pl-12 pr-4 py-3 border-2 border-gray-200 rounded-xl focus:ring-2 focus:ring-blue-400 focus:border-transparent transition-all duration-300"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>

          <div className="mb-8 flex flex-wrap gap-2">
            {['all', 'user', 'admin'].map((filterOption) => (
              <motion.button
                key={filterOption}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setFilter(filterOption)}
                className={`px-6 py-3 rounded-xl font-medium transition-all duration-300
                  ${filter === filterOption 
                    ? 'bg-gradient-to-r from-blue-500 to-blue-600 text-white shadow-lg' 
                    : 'bg-white text-gray-700 hover:bg-gray-50'}`}
              >
                {filterOption.charAt(0).toUpperCase() + filterOption.slice(1)}
              </motion.button>
            ))}
          </div>

          {loading ? (
            <div className="flex justify-center items-center h-64">
              <motion.div
                animate={{ rotate: 360 }}
                transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
              >
                <FaSpinner className="text-4xl text-blue-500" />
              </motion.div>
            </div>
          ) : (
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              <div className="space-y-6">
                <h2 className="text-2xl font-bold text-gray-800 mb-6 flex items-center">
                  <FaUserShield className="mr-2 text-green-500" /> 
                  Administrateurs 
                </h2>
                <AnimatePresence mode="popLayout">
                  {filteredUsers.filter(user => user.role === 'admin').map(user => (
                    <UserCard key={user._id} user={user} />
                  ))}
                </AnimatePresence>
              </div>
              <div className="space-y-6">
                <h2 className="text-2xl font-bold text-gray-800 mb-6 flex items-center">
                  <FaUser className="mr-2 text-blue-500" /> 
                  Utilisateurs 
                </h2>
                <AnimatePresence mode="popLayout">
                  {filteredUsers.filter(user => user.role === 'user').map(user => (
                    <UserCard key={user._id} user={user} />
                  ))}
                </AnimatePresence>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
      <ToastContainer 
        position="bottom-right"
        theme="colored"
        autoClose={3000}
      />
    </>
  );
}