import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  FaLinkedin, 
  FaTwitter, 
  FaInstagram,
  FaQuoteLeft,
  FaMapMarkedAlt,
  FaAward,
  FaClock,
  FaUsers,
  FaHistory,
  FaLeaf,
  FaGlobe,
  FaHandshake,
  FaFacebook,
  FaWhatsapp
} from 'react-icons/fa';

import NavBar from '../compoenents/NavBar';
import Footer from '../compoenents/footer';

const About = () => {
  const [selectedTab, setSelectedTab] = useState('histoire');
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
    // Scroll to top when tab changes
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [selectedTab]);

  const companyHistory = [
    {
      year: "2020",
      title: "Notre Début",
      description: "Commencé en tant que petite agence de conseil en voyages à Tunis.",
      icon: FaHistory
    },
    {
      year: "2021",
      title: "Expansion ",
      description: "Ouverture de notre service de voyage en Tunisie.",
      icon: FaGlobe
    },
    {
      year: "2024",
      title: "Transformation Digitale",
      description: "Lancement de notre plateforme de réservation numérique innovante.",
      icon: FaUsers
    },
    {
      year: "2024",
      title: "Initiative de Durabilité",
      description: "Mise en place de notre programme de voyage éco-responsable.",
      icon: FaLeaf
    }
  ];

  const teamMembers = [
    {
      name: "Karem",
      role: "PDG & Fondateur",
      description: "Avec plus de 15 ans d'expérience dans le voyage de luxe et la gestion d'événements.",
      image: "/karem.png",
      expertise: ["Planification Stratégique", "Opérations Globales", "Innovation"],
      social: {
        facebook: "https://www.facebook.com/username",
        whatsapp: "https://wa.me/+21658275926?text=Hello",
        instagram: "https://www.instagram.com/username"
      }
    },
  ];

  const stats = [
    { number: "3+", label: "Années d'Expérience", icon: FaClock },
    { number: "20+", label: "Pays Desservis", icon: FaMapMarkedAlt },
    { number: "700+", label: "Clients Satisfaits", icon: FaUsers },
    { number: "2+", label: "Prix de l'Industrie", icon: FaAward }
  ];

  const values = [
    { title: "Durabilité", description: "Engagé dans le voyage éco-responsable", icon: FaLeaf },
    { title: "Innovation", description: "Solutions numériques pionnières", icon: FaGlobe },
    { title: "Confiance", description: "Construire des relations durables", icon: FaHandshake }
  ];

  const pageTransition = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 }
  };

  return (
    <>
      <NavBar />
      <motion.div 
        className="bg-white mt-16"
        initial="initial"
        animate="animate"
        exit="exit"
        variants={pageTransition}
      >
        <div className="bg-gray-50 py-16 relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-r from-red-600/10 to-transparent" />
          <div className="max-w-7xl mx-auto px-4 relative">
            <motion.div 
              className="max-w-3xl"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              <h1 className="text-4xl font-bold mb-6">À Propos de Nous</h1>
              <nav className="flex space-x-1">
                {['histoire', 'équipe', 'parcours'].map((tab) => (
                  <motion.button
                    key={tab}
                    onClick={() => setSelectedTab(tab)}
                    className={`px-6 py-3 rounded-md transition-all duration-300 ${
                      selectedTab === tab 
                        ? 'bg-red-600 text-white shadow-lg' 
                        : 'text-gray-600 hover:bg-gray-200'
                    }`}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    {tab.charAt(0).toUpperCase() + tab.slice(1)}
                  </motion.button>
                ))}
              </nav>
            </motion.div>
          </div>
        </div>

        <div className="max-w-7xl mx-auto px-4 py-12">
          <AnimatePresence mode="wait">
            {selectedTab === 'histoire' && (
              <motion.div 
                key="histoire"
                className="grid grid-cols-1 lg:grid-cols-2 gap-12"
                {...pageTransition}
              >
                <div>
                  <h2 className="text-3xl font-bold mb-6">Notre Histoire</h2>
                  <div className="prose prose-lg">
                    <p className="leading-relaxed">
                      Fondée en 2020, notre mission était simple : transformer la manière dont les gens vivent les voyages et les événements. Ce qui a commencé comme une petite agence familiale est devenu une présence mondiale dans le voyage de luxe et la gestion d'événements.
                    </p>
                    <motion.blockquote 
                      className="border-l-4 border-red-600 pl-4 my-8"
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: 0.3 }}
                    >
                      <FaQuoteLeft className="text-red-600 text-2xl mb-4" />
                      <p className="text-xl font-medium text-gray-600">
                        "Nous croyons en la création de moments qui se transforment en souvenirs à vie."
                      </p>
                      <footer className="text-gray-500">- Karem, Fondateur</footer>
                    </motion.blockquote>
                    <p className="leading-relaxed">
                      Aujourd'hui, nous opérons dans plus de 20 pays, servant nos clients avec la même dévotion et touche personnelle qui définissaient nos débuts. Notre engagement envers l'excellence et l'innovation nous a valu de nombreux prix de l'industrie et la confiance de milliers de clients satisfaits.
                    </p>
                    
                    <div className="mt-12 grid grid-cols-1 md:grid-cols-3 gap-6">
                      {values.map((value, index) => (
                        <motion.div
                          key={index}
                          className="p-4 border border-gray-200 rounded-lg"
                          initial={{ opacity: 0, y: 20 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ delay: index * 0.1 }}
                        >
                          <value.icon className="text-red-600 text-2xl mb-2" />
                          <h3 className="font-bold mb-1">{value.title}</h3>
                          <p className="text-sm text-gray-600">{value.description}</p>
                        </motion.div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-6">
                  {stats.map((stat, index) => (
                    <motion.div
                      key={index}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: index * 0.1 }}
                      whileHover={{ scale: 1.05 }}
                      className="bg-gray-50 p-6 rounded-lg shadow-sm hover:shadow-md transition-all duration-300"
                    >
                      <stat.icon className="text-red-600 text-3xl mb-3" />
                      <div className="text-3xl font-bold text-gray-900 mb-1">
                        {stat.number}
                      </div>
                      <div className="text-gray-600">{stat.label}</div>
                    </motion.div>
                  ))}
                </div>
              </motion.div>
            )}

            {selectedTab === 'équipe' && (
              <motion.div
                key="équipe"
                {...pageTransition}
              >
                <h2 className="text-3xl font-bold mb-12">Notre Équipe de Direction</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                  {teamMembers.map((member, index) => (
                    <motion.div
                      key={index}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: index * 0.1 }}
                      whileHover={{ y: -10 }}
                      className="bg-gray-50 rounded-lg overflow-hidden shadow-sm hover:shadow-xl transition-all duration-300"
                    >
                      <div className="relative">
                        <img
                          src={member.image}
                          alt={member.name}
                          className="w-full h-64 object-cover"
                        />
                        <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/60 to-transparent p-4">
                          <h3 className="text-xl font-bold text-white mb-1">{member.name}</h3>
                          <p className="text-red-400">{member.role}</p>
                        </div>
                      </div>
                      <div className="p-6">
                        <p className="text-gray-600 mb-4">{member.description}</p>
                        <div className="mb-4">
                          <h4 className="font-semibold mb-2">Expertise :</h4>
                          <div className="flex flex-wrap gap-2">
                            {member.expertise.map((skill, idx) => (
                              <span key={idx} className="bg-red-100 text-red-600 px-3 py-1 rounded-full text-sm">
                                {skill}
                              </span>
                            ))}
                          </div>
                        </div>
                        <div className="flex space-x-4">
                          {Object.entries(member.social).map(([platform, link], idx) => (
                            <motion.a
                              key={idx}
                              href={link}
                              className="text-gray-400 hover:text-red-600 transition-colors"
                              whileHover={{ scale: 1.2 }}
                            >
                              {platform === 'linkedin' && <FaLinkedin size={20} />}
                              {platform === 'twitter' && <FaTwitter size={20} />}
                              {platform === 'instagram' && <FaInstagram size={20} />}
                              {platform === 'facebook' && <FaFacebook size={20} />}
                              {platform === 'whatsapp' && <FaWhatsapp size={20} />}
                            </motion.a>
                          ))}
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </motion.div>
            )}

            {selectedTab === 'parcours' && (
              <motion.div
                key="parcours"
                {...pageTransition}
              >
                <h2 className="text-3xl font-bold mb-12">Notre Parcours</h2>
                <div className="space-y-12">
                  {companyHistory.map((event, index) => (
                    <motion.div
                      key={index}
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: index * 0.1 }}
                      className="flex items-start space-x-6 group"
                    >
                      <div className="flex-shrink-0 w-24">
                        <motion.div 
                          className="text-xl font-bold text-red-600"
                          whileHover={{ scale: 1.1 }}
                        >
                          {event.year}
                        </motion.div>
                      </div>
                      <div className="flex-grow">
                        <div className="flex items-center mb-2">
                          <event.icon className="text-red-600 mr-2" />
                          <h3 className="text-xl font-bold">{event.title}</h3>
                        </div>
                        <p className="text-gray-600 group-hover:text-gray-900 transition-colors">
                          {event.description}
                        </p>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </motion.div>
      <Footer />
    </>
  );
};

export default About;