import React from "react";
import {
  FaHome,
  FaInfoCircle,
  FaEnvelope,
  FaShieldAlt,
  FaTwitter,
  FaPhone,
  FaMapMarkerAlt,
  FaFacebook,
  FaInstagram,
  FaWhatsapp
} from "react-icons/fa";

export default function Footer() {
  return (
    <footer className="relative bg-gradient-to-b from-red-800 to-red-900 overflow-hidden">
      {/* Pattern Overlay */}
      <div
        className="absolute inset-0 opacity-10"
        style={{
          backgroundImage: `linear-gradient(30deg, #000000 12%, transparent 12.5%, transparent 87%, #000000 87.5%, #000000),
            linear-gradient(150deg, #000000 12%, transparent 12.5%, transparent 87%, #000000 87.5%, #000000),
            linear-gradient(30deg, #000000 12%, transparent 12.5%, transparent 87%, #000000 87.5%, #000000),
            linear-gradient(150deg, #000000 12%, transparent 12.5%, transparent 87%, #000000 87.5%, #000000),
            linear-gradient(60deg, #00000077 25%, transparent 25.5%, transparent 75%, #00000077 75%, #00000077)`,
          backgroundSize: "80px 140px",
          backgroundPosition: "0 0, 0 0, 40px 70px, 40px 70px, 0 0",
        }}
      />

      {/* Top border accent */}

      <div className="max-w-6xl mx-auto px-4 py-12 relative z-10">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Brand Section */}
          <div className="space-y-4">
            <div className="flex items-center space-x-2">
              <h3 className="text-2xl font-bold text-white">Société karmini services et sponsoring </h3>
            </div>
            <p className="text-red-100 text-sm">
              Karmini Services et Sponsoring se spécialise dans des solutions de service sur mesure et un soutien complet en matière de parrainage pour les entreprises.
            </p>
            {/* Social Media Icons */}
            <div className="flex space-x-4 pt-2">
              <a
                href="https://www.facebook.com/profile.php?id=61551781324448&mibextid=ZbWKwL"
                className="text-red-200 hover:text-white transition-colors duration-300"
              >
                <FaFacebook size={20} />
              </a>
            </div>
          </div>

          {/* Quick Links */}
          <div className="space-y-4">
            <h4 className="text-lg font-semibold text-white">Liens Rapides</h4>
            <nav className="grid grid-cols-1 gap-2">
              <a
                href="/"
                className="text-red-200 hover:text-white transition-colors duration-300 flex items-center space-x-2"
              >
                <FaHome size={16} />
                <span>Accueil</span>
              </a>
              <a
                href="about"
                className="text-red-200 hover:text-white transition-colors duration-300 flex items-center space-x-2"
              >
                <FaInfoCircle size={16} />
                <span>À Propos</span>
              </a>
              <a
                href="mailto:karminiservice@gmail.com"
                className="text-red-200 hover:text-white transition-colors duration-300 flex items-center space-x-2"
              >
                <FaEnvelope size={16} />
                <span>Contact</span>
              </a>

              <a
                href="#"
                className="text-red-200 hover:text-white transition-colors duration-300 flex items-center space-x-2"
              >
                <FaShieldAlt size={16} />
                <span>Politique de Confidentialité</span>
              </a>
            </nav>
          </div>

          {/* Contact Info */}
          <div className="space-y-4">
            <h4 className="text-lg font-semibold text-white">Contactez-Nous</h4>
            <div className="space-y-3">
              <div className="flex items-center space-x-3 text-red-200">
                <FaMapMarkerAlt size={16} />
                <span className="text-sm">Rue Docteur Alphonse Laveran, Tunis, Tunisie, 1002</span>
              </div>
              <div className="flex items-center space-x-3 text-red-200">
                <FaPhone size={16} />
                <span className="text-sm">+216 58 275 926</span>
              </div>
              <div className="flex items-center space-x-3 text-red-200">
                <FaEnvelope size={16} />
                <span className="text-sm">karminiservice@gmail.com</span>
              </div>
            </div>
          </div>
        </div>

        {/* Copyright */}
        <div className="mt-8 pt-8 border-t border-red-700">
          <div className="flex flex-col md:flex-row justify-between items-center text-sm text-red-200">
            <p>
              &copy; {new Date().getFullYear()} Société karmini services et sponsoring. Tous droits réservés.
            </p>
            <p className="mt-2 md:mt-0 text-white flex items-center space-x-2 text-opacity-80">
      Conçu et réalisé par <span></span>
      <a
        href="https://wa.me/96224886" 
        target="_blank"
        rel="noopener noreferrer"
        className="hover:underline flex items-center space-x-1"
      >
        <span>Iyed Ben Mansour</span>
        <FaWhatsapp size={18} />
      </a>
    </p>
          </div>
        </div>
      </div>
    </footer>
  );
}